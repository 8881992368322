// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import NotistackProvider from "./components/NotistackProvider";
import DataErrorMonitor from "./components/DataErrorMonitor";
import ChatbotManager from "./components/ChatbotManager";

declare global {
  interface Window {
    Paddle: any;
    mixpanel: any;
  }
}

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <RtlLayout>
          <NotistackProvider>
            <DataErrorMonitor>
              {/*<Settings />*/}
              <ChatbotManager/>
              <ScrollToTop/>
              <Router/>
            </DataErrorMonitor>
          </NotistackProvider>
        </RtlLayout>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

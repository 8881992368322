import {Comparer, createSlice} from '@reduxjs/toolkit';
import {RootState, store} from '../store';
import axios from '../../utils/axios';
import {VideoData, VideoState} from '../../@types/video';
import {createVideoAdapter} from "../utils";
import compareDesc from 'date-fns/compareDesc';
import {toDate} from '../../utils/formatTime';

const apiBase = "/api/v2/videos"

const sortVideosComparer: Comparer<VideoData> = (a, b) => compareDesc(toDate(a.createdAt), toDate(b.createdAt));
const videoAdapter = createVideoAdapter(sortVideosComparer);

const initialState: VideoState = {
  isLoading: false,
  error: false,
  videoDataList: videoAdapter.getInitialState(),
  videoData: null,
  sortBy: null,
  deletingVideo: null,
  editingVideo: null
};

const slice = createSlice({
  name: 'video',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getVideoListSuccess(state, action) {
      state.isLoading = false;
      videoAdapter.setAll(state.videoDataList, action.payload);
    },

    getVideoSuccess(state, action) {
      state.isLoading = false;
      state.videoData = action.payload;
    },

    onVideoInListOperationSuccess(state) {
        state.isLoading = false;
    },

    updateVideo(state, action) {
      videoAdapter.updateOne(state.videoDataList, action.payload);
    },

    setVideoDelete(state, action) {
      state.deletingVideo = action.payload;
    },

    setVideoEdit(state, action) {
      state.editingVideo = action.payload;
    },

    deleteVideoFromState(state, action) {
      state.deletingVideo = null;
      videoAdapter.removeOne(state.videoDataList, action.payload);
    },

    sortByVideos(state, action) {
      state.sortBy = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByVideos,
  setVideoDelete,
  setVideoEdit
} = slice.actions;


// ----------------------------------------------------------------------

export function getVideoList() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: VideoData[] } = await axios.get(apiBase);
      dispatch(slice.actions.getVideoListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVideo(id: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { video: VideoData } } = await axios.get(apiBase + "/" + id);
      dispatch(slice.actions.getVideoSuccess(response.data.video));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveVideo(file: File, name: string, progressUpdater: (progress: number) => any) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);
      const { data } = await axios.post(apiBase + "/create", formData,
        {
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength !== null) {
              progressUpdater(Math.round( (progressEvent.loaded * 100) / totalLength ));
            }
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      dispatch(slice.actions.getVideoSuccess(data));
      await dispatch(getVideoList());
      return data.videoId;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function changeLikeStatus(id: number, liked: boolean) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    const request: any =
      {
        "id": id,
        "liked": liked
      };

    try {
      await axios.patch(apiBase + '/' + id, request);
      dispatch(slice.actions.updateVideo({
        id,
        changes: {
          liked
        }
      }));
      return dispatch(slice.actions.onVideoInListOperationSuccess());
    } catch (error) {
      return dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteVideo(id: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(apiBase + '/' + id);
      dispatch(slice.actions.onVideoInListOperationSuccess());
      dispatch(slice.actions.deleteVideoFromState(id));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export const updateVideo = (data: VideoData) => {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(apiBase + '/' + data.id, data);
      dispatch(slice.actions.onVideoInListOperationSuccess());
      dispatch(slice.actions.updateVideo({
        id: data.id,
        changes: {
          ...data
        }
      }));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  }
};

export const {
  selectAll: selectAllVideos,
  selectById: selectVideoById,
  selectIds: selectVideoIds
  // Pass in a selector that returns the posts slice of state
} = videoAdapter.getSelectors((state: RootState) => state.video.videoDataList)

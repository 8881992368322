import {useTranslation} from 'react-i18next';
// material
import {enUS, ruRU} from '@material-ui/core/locale';
import {parseSimpleLanguage} from "../utils/dataUtils";
// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/static/icons/ic_flag_en.svg'
  },
  {
    label: 'Русский',
    value: 'ru',
    systemValue: ruRU,
    icon: '/static/icons/ic_flag_ru.svg'
  }
];

export default function useLocales() {
  const {i18n, t: translate} = useTranslation();
  let langStorage = localStorage.getItem('i18nextLng');

  if (langStorage) {
    langStorage = parseSimpleLanguage(langStorage)
  }
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0]

  const handleChangeLanguage = async (newlang: string) => {
    await i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  };
}

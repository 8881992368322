import {Container} from '@material-ui/core';

import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import useLocales from "../../hooks/useLocales";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

import ToHomeLayout from '../../layouts/campaign/ToHomeLayout';
import {RootState} from "../../redux/store";
import {getProfile} from "../../redux/slices/user";
import WidgetForm from "./WidgetForm";
import {useParams} from "react-router-dom";

export default function WidgetEditPage() {
    const {themeStretch} = useSettings();
    const dispatch = useDispatch();
    const {translate} = useLocales();
    const {userProfile} = useSelector((state: RootState) => state.user);
    const { widgetId } = useParams();
    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    const mixpanel = window.mixpanel;
    useEffect(() => {
        if (userProfile) {
            mixpanel?.track("edit widget start", {
                uid: userProfile.id,
            });
        }
    }, [userProfile != null]);

    console.log("editing widget: " + widgetId)
    return (
        <ToHomeLayout isHomeButtonRendered={true}>
            <Page title={translate('widget.breadcrumbs.editWidget')}>
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <WidgetForm editingWidgetId={Number(widgetId)} nextStage={() => {}} />
                </Container>
            </Page>
        </ToHomeLayout>
    );
}

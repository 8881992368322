import React from 'react';

import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { formatDurationInSeconds } from "../../../utils/formatTime";
import { useField } from "formik";

import useLocales from "../../../hooks/useLocales";

const StyledSlider = styled(Slider)(({ theme }) => ({
    height: 2,
    '& .MuiSlider-thumb': {
        height: 10,
        width: 10,
        color: '#7291FC'
    },
    '& .MuiSlider-valueLabel': {
        color: '#7291FC',
        top: 0,
        backgroundColor: 'unset',
        '&:before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
        color: '#7291FC',
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: '#7291FC',
        },
    }
}));

type CtaDelaySliderProps = {
    fieldName: string;
};

const CtaDelaySlider = ({ fieldName }: CtaDelaySliderProps) => {
    const [field] = useField(fieldName);
    const { translate } = useLocales();

    const lengthMarks = [
        {
            value: 5,
            label: `5 ${translate(`widget.cta.ctaDelay.seconds`)}`
        },
        {
            value: 15,
            label: `15 ${translate(`widget.cta.ctaDelay.seconds`)}`
        },
        {
            value: 30,
            label: `30 ${translate(`widget.cta.ctaDelay.seconds`)}`
        },
        {
            value: 45,
            label: `45 ${translate(`widget.cta.ctaDelay.seconds`)}`
        },
        {
            value: 60,
            label: `${translate(`widget.cta.ctaDelay.neverValue`)}`
        }
    ];

    return (<Box sx={{ pl: 3, pr: 3 }}>
        <StyledSlider
            {...field}
            min={0}
            max={60}
            step={1}
            valueLabelDisplay="auto"
            valueLabelFormat={val => <>{
                val !== 60 ?
                  formatDurationInSeconds(val) :
                  translate(`widget.cta.ctaDelay.neverShown`)
            }</>}
            marks={lengthMarks}
        />
    </Box>);
};

export default CtaDelaySlider;

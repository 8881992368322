import {createSlice} from '@reduxjs/toolkit';
import {store} from '../store';
import axios from '../../utils/axios';
import {TestimonialsCollection} from "../../@types/testimonialsCollection";
import {InitialID} from "../../@types/testimonialsCollection";
import {EmptyID} from "../../@types/testimonialsCollection";

const apiBase = "/api/v1/testimonials/"

type CollectionState = {
  isLoading: boolean;
  error: boolean;
  collectionInfo: null | TestimonialsCollection;
  lastCreated: TestimonialsCollection;
};

const initialState: CollectionState = {
  isLoading: false,
  error: false,
  collectionInfo: null,
  lastCreated: {publicId: InitialID},
};

const slice = createSlice({
  name: 'testimonialsCollectionReducer',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    createCollectionSuccess(state, action) {
      state.isLoading = false;
      state.collectionInfo = action.payload;
    },

    getLatestCollectionSuccess(state, action) {
      state.isLoading = false;
      state.lastCreated = action.payload;
    },

    getLatestCollectionNoData(state, action) {
      state.isLoading = false;
      state.lastCreated = {publicId: EmptyID};
    },
  }
});

export default slice.reducer;

export const createTestimonialsCollection = (reviewIds: number[]) => {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(apiBase + 'create-collection', {
        "reviewIds": reviewIds
      });
      dispatch(slice.actions.createCollectionSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  }
};

export const loadLatestReviewCollection = () => {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(apiBase + 'latest');
      if (response.status == 204) {
        // no collections yet
        dispatch(slice.actions.getLatestCollectionNoData(response.data));
        return true;
      }
      dispatch(slice.actions.getLatestCollectionSuccess(response.data));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  }
};

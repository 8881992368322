import {createContext, ReactNode, useEffect, useReducer} from 'react';
// utils
import axios from '../utils/axios';
import {isValidToken, setSession} from '../utils/jwt';
// @types
import {ActionMap, AuthState, AuthUser, JWTContextType} from '../@types/authentication';
import useLocales from "../hooks/useLocales";

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    error: boolean;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: false
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        error: action.payload.error
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({children}: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const {onChangeLang} = useLocales();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/api/v2/users/get');
          const lang = response.data && response.data['lang'] ? response.data['lang'] : "en"
          await onChangeLang(lang)

          const {user} = response.data;

          dispatch({
            type: Types.Initial,
            payload: {
              error: false,
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              error: false,
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            error: true,
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post('/perform-login', {
      email,
      password
    });

    const {success, authFailed, token, user} = response.data;
    let result = {success: success, authFailed: authFailed, user: user};

    const mixpanel = window.mixpanel;
    if (success) {
      mixpanel?.track('login success', {
        uid: user?.id,
      })
      setSession(token);

      const lang = user && user['lang'] ? user['lang'] : "en";
      await onChangeLang(lang)

      dispatch({
        type: Types.Login,
        payload: {
          user
        }
      });
    } else {
      mixpanel?.track('login fail', {
        authFailed: authFailed,
      })
    }
    return Promise.resolve(result)
  };

  const register = async (email: string, password: string, domain: string, name: string, language: string, promo: string, referrer: string, referralId: string, origin: string) => {
    const response = await axios.post('/api/v1/auth/register', {
      email,
      password,
      domain,
      name,
      language,
      promo,
      origin,
      referrer,
      referralId
    });
    const {success, errorMessage, token} = response.data;

    let registrationInfo = {
      success: success,
      error: errorMessage,
      token: token
    };
    const mixpanel = window.mixpanel;
    if (success) {
      mixpanel?.track('signup success', {
        language: language,
        ref: referrer,
        origin: origin
      });
      window.localStorage.setItem('accessToken', token);
      dispatch({
        type: Types.Register,
        payload: {
          user: registrationInfo
        }
      });
    } else {
      mixpanel?.track('signup fail', {
        language: language,
        origin: origin,
        ref: referrer,
        error: errorMessage,
      });
    }
    return Promise.resolve(registrationInfo)
  };

  const logout = async () => {
    setSession(null);
    dispatch({type: Types.Logout});
  };

  const resetPassword = async (email: string) => {
    const response = await axios.put('/external-api/users/restore-password/' + email);
    const {success} = response.data;
    return Promise.resolve(success)
  };

  const updatePassword = async (token: string, password: string) => {
    const response = await axios.post('/external-api/users/update-password',
      {password, token});
    const {success, email} = response.data;
    if (success) {
      await login(email, password);
    }
    return Promise.resolve(success)
  };

  const updateProfile = () => {
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        updatePassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export {AuthContext, AuthProvider};

import React, {useState} from 'react';

import {useSelector} from 'react-redux';

import {styled} from '@mui/material/styles';
import {keyframes} from '@mui/system';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import bgSvg from '../../assets/CompleteSVG.svg';
import {Button, Tooltip} from "@material-ui/core";
import CopyToClipboard from "react-copy-to-clipboard";
import useLocales from "../../hooks/useLocales";
import {Link as RouterLink} from "react-router-dom";
import {PATH_DASHBOARD} from "../../routes/paths";
import {isMobile} from "react-device-detect";
import {TextField} from "@mui/material";
import {ContentCopyOutlined} from "@material-ui/icons";
import {getWidgetCode, isEmbedded} from "../../redux/slices/widgetCreate";

const expandingAnimation = keyframes`
  0% {
    transform: translateX(-50%) scale(0.2);
    opacity: 0.5;
  }
  100% {
    transform: translateX(-50%) scale(0.9);
    opacity: 1;
  }
`;

const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative'
});

const StyledInnerContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledBGImage = styled('img')({
  position: 'absolute',
  zIndex: -1,
  left: '50%',
  transform: 'translateX(-50%) scale(0.9)',
  animation: `700ms 200ms ease-out 1 ${expandingAnimation}`
});

const StyledText = styled(Typography)({
  color: '#9296a4',
  fontSize: 14
})

const StyledHeader = styled(Typography)({
  color: '#9296a4',
  fontSize: 18
})

const FinishWidgetCreatePage = () => {
  const publicLink = useSelector(getWidgetCode);
  const isEmbeddedWidget = useSelector(isEmbedded);
  const [isCopied, setIsCopied] = useState(false);
  const {translate} = useLocales();

  const onCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1000);
  }

  type PublicLinkTextProps = {
    url: string;
    caption?: string;
    multiline?: boolean;
  }

  const PublicLinkText = (props: PublicLinkTextProps) => (
    <div style={{alignContent:'center', display: 'grid', marginTop: 10, width: 500, maxWidth: '95%'}}>
      <StyledHeader align="center" style={{margin: 'auto'}}>{props.caption}:</StyledHeader>
      <TextField
        disabled
        multiline={props.multiline}
        value={props.url}
        sx={{
          my: 2,
          textTransform: 'none',
          borderRadius: '50px',
          minHeight: 50,
          width: '100%'
        }}
        inputProps={{min: 0, style: {textAlign: 'center'}}}
        InputProps={{endAdornment: <CopyButton url={props.url}/>, disableUnderline: false}}
      />
    </div>
  )

  const CopyButton = (props: PublicLinkTextProps) => (
    <CopyToClipboard
      text={props.url}
      onCopy={onCopy}
    >
      <Tooltip
        title={
          isCopied
            ? translate('campaignCreateFinishPage.copyToolTipAfterClick')!
            : translate('campaignCreateFinishPage.copyTooltip')!
        }
        placement="top" arrow
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: 50,
            minHeight: 50,
            minWidth: 80,
          }}
        >
          <ContentCopyOutlined style={{color: "white"}}/>
        </Button>
      </Tooltip>
    </CopyToClipboard>
  )

  return (
    <StyledContainer>
      <StyledInnerContainer sx={{width: {xs: '90%', md: '70%'}}}>
        <Typography
          variant="h2"
          align="center"
          sx={{
            pt: 2,
            color: 'primary.main',
            marginBottom: '2vh'
          }}
        >
          {translate('widget.finish.titleText')}
        </Typography>
        <StyledText align="center">{translate('widget.finish.helperText1')}</StyledText>
        {isEmbeddedWidget && <StyledText align="center">{translate('widget.finish.helperTextEmbed')}</StyledText>}
        <PublicLinkText url={publicLink} caption={translate('widget.finish.codeTitle')} multiline={true}/>

        {!isMobile && <Box sx={{
          display: 'flex',
          justifyContent: {
            xs: 'center',
            md: 'flex-end'
          },
          width: '100%',
          pb: {
            xs: '20px'
          }
        }}>
            <Button
                variant="contained"
                component={RouterLink}
                to={PATH_DASHBOARD.general.dashboard}
                sx={{
                  px: 6,
                  backgroundColor: '#f4f6f8',
                  color: 'primary.main',
                  borderWidth: 0,
                  borderRadius: 50,
                  minHeight: 50,
                  textAlign: 'center',
                  '&:hover': {
                    color: '#f4f6f8',
                    backgroundColor: 'primary.main',
                  }
                }}
            >
              {translate('campaignCreateFinishPage.toAccountButton')}
            </Button>
        </Box>}
      </StyledInnerContainer>
      <StyledBGImage src={bgSvg} aria-label="hidden" alt="congrats-bg"/>
    </StyledContainer>
  );
};

export default FinishWidgetCreatePage;

import React, {useCallback, useRef, useState} from 'react';
import {useField} from "formik";
import {HexColorPicker} from "react-colorful";

import Box from "@mui/material/Box";
import useClickOutside from "./useClickOutside";
import {styled} from "@material-ui/core/styles";

type ColorPickerProps = {
  fieldName: string;

};

const StyledContainer = styled(Box)({
  position: 'relative',
});

const StyledPopover = styled(Box)({
  position: 'absolute',
  top: 'calc(100% + 2px)',
  left: 0,
  borderRadius: '9px',
  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
  zIndex: 10000,
});

const StyledInnerContainer = styled(Box)({
  width: '28px',
  height: '28px',
  borderRadius: '8px',
  border: '3px solid #fff',
  boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
});

const ColorPickerField = ({fieldName}: ColorPickerProps) => {
  const [field, meta, helper] = useField(fieldName);
  const [color, setColor] = useState(field.value);
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  return (
    <Box sx={{pl: 3, pr: 3}}>
      <StyledContainer>
        <StyledInnerContainer
          style={{backgroundColor: color}}
          onClick={() => toggle(true)}
        />

        {isOpen && (
          <StyledPopover ref={popover}>
            <HexColorPicker color={color} onChange={(c: any) => {
              setColor(c);
              helper.setValue(c);
            }}
            />
          </StyledPopover>
        )}
      </StyledContainer>
    </Box>
  );
};

export default ColorPickerField;

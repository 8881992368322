import React, { useCallback } from 'react';
import { IconButton } from "@material-ui/core";
import { PATH_DASHBOARD } from "../../routes/paths";
import { Icon } from "@iconify/react";
import CloseIcon from "@iconify/icons-eva/close-fill";

import { useNavigate } from "react-router-dom";

const ToHomeButton = () => {
    const navigate = useNavigate();
    const onClickCb = useCallback(() => {
        navigate(PATH_DASHBOARD.root);
    }, [navigate]);

    return (
        <IconButton
            sx={{
                position: 'fixed',
                zIndex: 999,
                right: 8,
                top: 8,
                color: 'primary.main'
            }}
            onClick={onClickCb}
        >
            <Icon icon={CloseIcon} />
        </IconButton>
    );
};

export default ToHomeButton;
import {useState} from 'react';
import {Icon} from '@iconify/react';
import {matchPath, NavLink as RouterLink, useLocation} from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import {alpha, styled, useTheme} from '@material-ui/core/styles';
import {
  Box,
  BoxProps,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@material-ui/core';
// theme
import typography from '../theme/typography';
import useLocales from "../hooks/useLocales";

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({theme}) => ({
  ...typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary
}));

const ListItemStyle = styled(ListItemButton)(({theme}) => ({
  ...typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'none',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  external?: boolean;
  children?: {
    title: string;
    path: string;
  }[];
};

function NavItem({item, isShow}: { item: NavItemProps; isShow?: boolean | undefined }) {
  const theme = useTheme();
  const {pathname} = useLocation();
  const {title, external, path, icon, info, children} = item;
  const isActiveRoot = path ? !!matchPath({path, end: false}, pathname) : false;
  const {translate} = useLocales();
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': {display: 'block'}
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          disableGutters
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={translate(title)}/>
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{width: 16, height: 16, ml: 1}}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const {title, path} = item;
                const isActiveSub = path ? !!matchPath({path, end: false}, pathname) : false;

                return (
                  <ListItemStyle
                    disableGutters
                    key={title}
                    // @ts-ignore
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle)
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main'
                          })
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={translate(item.title)}/>
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <>
      {!external &&
      <ListItemStyle
          disableGutters
        // @ts-ignore
          component={RouterLink}
          to={path}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
      >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        {isShow && (
          <>
            <ListItemText disableTypography primary={translate(item.title)}/>
            {info && info}
          </>
        )}
      </ListItemStyle>
      }
      {external &&
      <ListItemStyle
          disableGutters
        // @ts-ignore
          href={path}
          component="a"
          target={"_blank"}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
      >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        {isShow && (
          <>
            <ListItemText disableTypography primary={translate(item.title)}/>
            {info && info}
          </>
        )}
      </ListItemStyle>}
    </>
  );
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined;
  navConfig: {
    subheader: string;
    items: NavItemProps[];
  }[];
}

export default function NavSection({navConfig, isShow = true, ...other}: NavSectionProps) {
  const {translate} = useLocales();

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const {subheader, items} = list;
        return (
          <List key={subheader} disablePadding>
            {isShow && <ListSubheaderStyle>{translate(subheader)}</ListSubheaderStyle>}
            {items.map((item: NavItemProps) => (
              <NavItem key={item.title} item={item} isShow={isShow}/>
            ))}
          </List>
        );
      })}
    </Box>
  );
}

import React from 'react';

import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { formatDurationInSeconds } from "../../../utils/formatTime";
import { useField } from "formik";

import useLocales from "../../../hooks/useLocales";

const StyledSlider = styled(Slider)(({ theme }) => ({
    height: 2,
    '& .MuiSlider-thumb': {
        height: 10,
        width: 10,
        color: '#7291FC'
    },
    '& .MuiSlider-valueLabel': {
        color: '#7291FC',
        top: 0,
        backgroundColor: 'unset',
        '&:before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
        color: '#7291FC',
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: '#7291FC',
        },
    }
}));

type VideoLengthSliderProps = {
    fieldName: string;
};

const VideoLengthSlider = ({ fieldName }: VideoLengthSliderProps) => {
    const [field] = useField(fieldName);
    const { translate } = useLocales();
    const translateKey = 'campaign.appearance.videoLength.measurements';

    const lengthMarks = [{
        value: 30,
        label: `30 ${translate(`${translateKey}.seconds`)}`
    },
        {
            value: 60
        },
        {
            value: 90
        },
        {
            value: 120
        },
        {
            value: 150
        },
        {
            value: 180
        },
        {
            value: 210
        },
        {
            value: 240
        },
        {
            value: 270
        },
        {
            value: 300,
            label: `5 ${translate(`${translateKey}.minutes`)}`
        },
    ];

    return (<Box sx={{ pl: 3, pr: 3 }}>
        <StyledSlider
            {...field}
            min={30}
            max={300}
            step={30}
            valueLabelDisplay="auto"
            valueLabelFormat={val => <>{formatDurationInSeconds(val)}</>}
            marks={lengthMarks}
        />
    </Box>);
};

export default VideoLengthSlider;
import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogContent, Grid, Typography} from '@material-ui/core';
import {RootState, useDispatch, useSelector} from "../../redux/store";
import {getVideoList, selectVideoById, selectVideoIds} from "../../redux/slices/videos";
import VideoCardForSelect from "./VideoCardForSelect";
import {VideoData} from "../../@types/video";
import {styled} from "@material-ui/core/styles";
import useLocales from "../../hooks/useLocales";

type SelectVideoDialogProps = {
  open: boolean,
  setOpen: (value: boolean) => void,
  nextStage: () => void
  onVideoSelectConfirm: () => void;
  selectedVideoId: number | undefined;
  onVideoSelect: (id: any) => void;
};

const StyledDialog = styled(Dialog)({
  '& .MuiBackdrop-root': {
    background: 'rgb(255 255 255 / 99%)'
  },
  '& .MuiPaper-root': {
    boxShadow: '0 0 40px 6px rgb(69 71 72 / 26%), 0 24px 48px 0px rgb(145 158 171 / 24%)',
  }
});

const StyledButtonContainer = styled('div')({
  position: 'fixed',
  zIndex: 1301,
  bottom: '4%',
  left: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const StyledVideo = styled('video')({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  position: 'absolute',
});

const SelectVideoDialog = ({
                             open,
                             setOpen,
                             nextStage,
                             onVideoSelectConfirm,
                             selectedVideoId,
                             onVideoSelect
                           }: SelectVideoDialogProps) => {
  const dispatch = useDispatch();
  const videoDataListIds = useSelector(selectVideoIds);
  const isVideosEmpty = videoDataListIds.length === 0;
  const {videoUrl} = useSelector((state: RootState) => selectVideoById(state, selectedVideoId!)) || {} as VideoData;
  const {translate} = useLocales();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const closeConfirmDialog = () => {
    setOpenConfirmDialog(false);
    onVideoSelect(null);
  };

  const onConfirm = () => {
    setOpenConfirmDialog(false);
    setOpen(false);
    onVideoSelectConfirm();
    nextStage();
  }

  useEffect(() => {
    dispatch(getVideoList());
  }, [dispatch]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {isVideosEmpty &&
              <Typography variant={"h4"} align="center" sx={{px: 3, pt: 3, pb: 6}}>
                {translate('video.emptyListWarning')}
              </Typography>
          }
          <Grid container spacing={3}>
            {videoDataListIds?.map((id) => (
              <Grid key={id} item xs={12} sm={6} md={4}>
                <VideoCardForSelect
                  videoId={id}
                  openPreviewModal={() => setOpenConfirmDialog(true)}
                  selectVideo={onVideoSelect}
                  selectedVideoId={selectedVideoId}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
      <StyledDialog
        open={openConfirmDialog}
        onClose={closeConfirmDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{
          position: 'relative',
          backgroundColor: '#000',
          padding: 0,
          paddingBottom: '56.25%'
        }}>
          <StyledVideo
            playsInline={true}
            controls={true}
            src={videoUrl}
          />
        </DialogContent>
      </StyledDialog>
      {openConfirmDialog &&
          <StyledButtonContainer>
              <Button
                  variant="outlined"
                  size="large"
                  onClick={closeConfirmDialog}
                  sx={{
                    borderRadius: 50,
                    marginRight: '1vw'
                  }}
              >
                {translate('selectVideoDialog.selectAnotherButton')}
              </Button>
              <Button
                  variant="contained"
                  size="large"
                  onClick={onConfirm}
                  sx={{
                    borderRadius: 50,
                    width: 180
                  }}
              >
                {translate('selectVideoDialog.nextButton')}
              </Button>
          </StyledButtonContainer>
      }
    </>
  );
};

export default SelectVideoDialog;

import React, {useState, useEffect, useRef, Fragment} from 'react';

import { Field, FieldProps, useField } from 'formik';

import { Icon } from '@iconify/react';
import plusOutline from '@iconify/icons-eva/plus-outline';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import useLocale from '../../../hooks/useLocales';
import useScrollToInvalidField from "../../../hooks/useScrollToInvalidField";

type CampaignFieldsArrayProps = {
    name: string;
    userFields?: string[];
    maxUserFields?: number;
    withoutNewFields?: boolean;
    staticField?: string;
};

export const defaultFieldsValues = [
    'questions.standard.name',
    'questions.standard.email',
    'questions.standard.companyName',
    'questions.standard.jobTitle',
    'questions.standard.socialLink'
];

const CampaignCheckboxesArray = ({ name, maxUserFields, userFields, withoutNewFields, staticField }: CampaignFieldsArrayProps) => {
    const [_, meta] = useField(name);
    const [field] = useField(staticField ?? name)
    const { translate } = useLocale();
    const translationPrefix = 'campaign.testimonialsCollection.fields';
    const [fields, setField] = useState(defaultFieldsValues);
    const [isEdited, setIsEdited] = useState(false);
    const [newFieldValue, setNewFieldValue] = useState('');

    const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
    const containerRef = useScrollToInvalidField<HTMLDivElement>(name);

    const maxFields = maxUserFields ? maxUserFields + 5 : null;
    const isAddFieldButtonRendered = !withoutNewFields && (!maxFields || maxFields > fields.length);

    useEffect(() => {
        if (isEdited && inputRef.current) {
            inputRef?.current!.focus();
        }
    }, [isEdited, inputRef]);

    useEffect(() => {
        if (userFields && !staticField) {
            setField(prevState => {
                if (userFields.every(item => prevState.includes(item))) {
                    return prevState;
                } else {
                    return [...defaultFieldsValues, ...userFields];
                }
            });
        }
    }, [userFields]);

    useEffect(() => {
        if (staticField && field && field.value.length > 0) {
            setField(field.value);
        }
    }, [field, staticField, field?.value])

    return (<Grid ref={containerRef} container spacing={1}>
            {(meta.touched && meta.error) && (
                <Grid item xs={12}>
                    <Alert severity="error">{translate(meta.error)}</Alert>
                </Grid>) }
            {fields.length && fields.map((el: string) => {
                const translateKey = `${translationPrefix}.${el}`;
                const translatedString = translate(translateKey);

                const visibleString = translateKey === translatedString ? el : translatedString;

                return (<Grid key={el} item xs={12} sm={4}>
                    <FormControlLabel sx={{ color: '#919EAB' }} control={
                         <Field type="checkbox" name={name} value={el}>
                            {({ field }: FieldProps) => (
                                <Checkbox {...field}
                                    sx={{
                                        color: '#7291FC',
                                        '&.Mui-checked': {
                                            color: '#7291FC',
                                        },
                                    }}
                            />)}
                        </Field>
                    } label={visibleString} />
                </Grid>);
            })}
            {isEdited && <Grid item xs={12} sm={8}>
                <TextField ref={inputRef}
                           variant="standard"
                           size="small"
                           fullWidth
                           placeholder={translate('campaign.testimonialsCollection.placeholder')}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setNewFieldValue(e.target.value);
                           }}
                           onKeyPress={(e: React.KeyboardEvent) => {
                               if (e.key === 'Enter' && newFieldValue) {
                                   setField(prevState => [...prevState, newFieldValue]);
                                   setIsEdited(false);
                                   setNewFieldValue('');
                               }
                           }}
                           onBlur={() => {
                               if (newFieldValue) {
                                   setField(prevState => [...prevState, newFieldValue]);
                                   setIsEdited(false);
                                   setNewFieldValue('');
                               }
                           }}
                           value={newFieldValue}
                />
            </Grid>}
            {isAddFieldButtonRendered && (<Grid key="add-field-button" item xs={8} sm={4}>
                <Button disabled={isEdited} onClick={() => setIsEdited(true)} startIcon={<Icon icon={plusOutline} />}>
                    {translate('campaign.testimonialsCollection.addButton')}
                </Button>
            </Grid>)}
        </Grid>);
};

export default CampaignCheckboxesArray;

import React, { ReactNode } from 'react';

import ToHomeButton from '../../components/navigation/ToHomeButton';

type ToHomeLayoutProps = {
  children: ReactNode;
  isHomeButtonRendered?: boolean;
};

const ToHomeLayout = ({ children, isHomeButtonRendered }: ToHomeLayoutProps) => {
    return (
        <>
            {isHomeButtonRendered && <ToHomeButton />}
            {children}
        </>
    );
};

export default ToHomeLayout;
import {ApplicationStateSlice} from "../../@types/applicationStateTypes";
import {createSlice} from "@reduxjs/toolkit";

const initialState: ApplicationStateSlice = {
    isRightAfterRegistration: false
};

const slice = createSlice({
    name: 'applicationState',
    initialState,
    reducers: {
        setRightAfterRegistrationFlag(state, {payload}) {
            state.isRightAfterRegistration = payload;
        }
    }
});

export const {
    setRightAfterRegistrationFlag
} = slice.actions;

export default slice.reducer;
import {Container} from '@material-ui/core';

import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import useLocales from "../../hooks/useLocales";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

import {isMobile} from 'react-device-detect';

import ToHomeLayout from '../../layouts/campaign/ToHomeLayout';
import {RootState} from "../../redux/store";
import {getProfile} from "../../redux/slices/user";
import WidgetIntroPage from "./WidgetIntroPage";
import WidgetCreate from "./WidgetForm";
import {CREATE_WIDGET_STAGES} from "../../@types/widget";
import {getActiveStageKey, setActiveStageTransitionStart, setInitialState} from "../../redux/slices/widgetCreate";
import FinishWidgetCreatePage from "./FinishWidgetCreatePage";

export default function WidgetCreatePage() {
    const {themeStretch} = useSettings();
    const dispatch = useDispatch();
    const activeStep = useSelector(getActiveStageKey);
    const {translate} = useLocales();
    const {userProfile} = useSelector((state: RootState) => state.user);

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    const mixpanel = window.mixpanel;
    useEffect(() => {
        if (userProfile) {
            mixpanel?.track("create widget start", {
                uid: userProfile.id,
            });
        }
    }, [userProfile != null]);

    const isComplete = CREATE_WIDGET_STAGES.indexOf(activeStep) === CREATE_WIDGET_STAGES.length - 1;

    const handleStageChange = (stage: string, isImmediateTransition: boolean) => () => {
        dispatch(setActiveStageTransitionStart({
            stageKey: stage,
            isImmediateTransition
        }));

        window.scrollTo(0, 100)
    }

    useEffect(() => {
        dispatch(setInitialState());
    }, []);

    return (
        <ToHomeLayout isHomeButtonRendered={true}>
            <Page title={translate('widget.breadcrumbs.newWidget')} sx={{ pt: !isComplete && !isMobile ? 7 : 4, pb: 3  }}>
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    {!isComplete ? (
                        <>
                            {activeStep === CREATE_WIDGET_STAGES[0] &&
                            <div>
                                <WidgetIntroPage nextStage={handleStageChange(CREATE_WIDGET_STAGES[1], true)}/>
                            </div>
                            }
                            {activeStep === CREATE_WIDGET_STAGES[1] &&
                            <div>
                                <WidgetCreate nextStage={handleStageChange(CREATE_WIDGET_STAGES[2], false)}/>
                            </div>
                            }
                        </>
                    ) : (<FinishWidgetCreatePage />
                    )}
                </Container>
            </Page>
        </ToHomeLayout>
    );
}

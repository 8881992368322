import {addSeconds, format, formatDistanceToNow} from 'date-fns';

// ----------------------------------------------------------------------

export function toDate(dateComponents: number[]) {
  return new Date(dateComponents[0], dateComponents[1] - 1, dateComponents[2], dateComponents[3], dateComponents[4], dateComponents[5])
}

export function formatDate(date: Date) {
  return format(date, 'dd MMMM yyyy');
}

export function formatNumDate(date: number[]) {
  return format(toDate(date), 'dd.MM.yyyy');
}

export function fDateTime(date: number[]) {
  return format(toDate(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date: string | number | Date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: string | number | Date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export const formatDurationInSeconds = (durationInSeconds: number | null | undefined) => {
  if (typeof durationInSeconds === 'number')  {
    return format(addSeconds(new Date(0), durationInSeconds), 'm:ss')
  }
  return format(new Date(0), 'm:ss');
};

import {useEffect, useRef, useState} from 'react';
// material
import {Box, ListItemIcon, ListItemText, MenuItem} from '@material-ui/core';
// components
import MenuPopover from '../../components/MenuPopover';
import {MIconButton} from '../../components/@material-extend';
import useLocales from "../../hooks/useLocales";
import {User} from "../../@types/user";
import {saveUser} from "../../redux/slices/user";
import {dispatch, RootState, useSelector} from "../../redux/store";
import {parseSimpleLanguage} from "../../utils/dataUtils";

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { allLang, currentLang, onChangeLang } = useLocales();
  const {userProfile} = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (userProfile && userProfile.lang) {
      onChangeLang(userProfile.lang)
    }
  }, [dispatch, userProfile]);

  const handleLanguageChange = async function(lang: string) {
    setOpen(false)
    const user: User = {
      lang: parseSimpleLanguage(lang)
    }
    await dispatch(saveUser(user))
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...({ bgcolor: 'action.selected' })
        }}
      >
        <img src={currentLang.icon} alt={currentLang.label} />
      </MIconButton>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {allLang.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                handleLanguageChange(option.value);
              }}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Box component="img" alt={option.label} src={option.icon} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}

import {EntityState} from "@reduxjs/toolkit";

export type CampaignInListData = {
  id: number;
  createdAt: number;

  name: string;
  collectedReviews: number;
  reviewStarted: number;
  enabled: boolean;
  publicId: string;
  wallPublicId: string;
};

export type CampaignData = {
  id: number;
  createdAt: number[];

  name: string;
  lang: string;
  externalUrl: string;
  externalUrlText: string;
  introText: string;
  finishText: string;
  description: string;
  questionExampleHeader: string;
  questionExamples: string[];
  promoText: string;
  logoImageId: number;
  introVideoId: number;
  color: string;
  maxVideoLengthSeconds: number;
  collectedData?: Map<string, string>
  requiredCollectedData?: string[]
};

export type CampaignCreateFormData = {
  name?: string;
  lang?: string;
  externalUrl?: string;
  externalUrlText?: string;
  introText?: string;
  finishText?: string;
  description?: string;
  questionExampleHeader?: string;
  questionExamples?: string[];
  promoText?: string;
  logoImageId?: string | number | undefined;
  introVideoId?: number;
  color?: string;
  maxVideoLengthSeconds?: number;
  collectedData?: {
    [key: string]: string;
  },
  requiredCollectedData?: string[]
}
export type CampaignEditFormData = {
  id: string;
  name?: string;
  lang?: string;
  externalUrl?: string;
  externalUrlText?: string;
  introText?: string;
  finishText?: string;
  description?: string;
  questionExampleHeader?: string;
  questionExamples?: string[];
  promoText?: string;
  color?: string;
  maxVideoLengthSeconds?: number;
  collectedData?: {
    [key: string]: string;
  },
  requiredCollectedData?: string[]
}

export const CREATE_CAMPAIGN_MODS = [
  'WithVideo',
  'WithoutVideo'
] as const;

export const CREATE_CAMPAIGN_STAGES = [
    'Intro',
    'CreateCampaign',
    'Finish'
] as const;


export const STEPPER_STEPS = [
  'campaign.steps.intro',
  'campaign.steps.content',
  'campaign.steps.appearance',
  'campaign.steps.testimonialsCollection',
  'campaign.steps.finish'
] as const;

export type CampaignCreateSlice = {
  createCampaignManager: {
    isLoading: boolean;
    error: string | null;
    data: any;
  },
  uploadVideoManager: {
    percent: number;
    isLoading: boolean;
    data: any;
  },
  uploadImageManager: {
    isLoading: false,
    data: any;
  },
  skippedFields: string[];
  introVideoId?: number;
  introText?: string;
  activeStage: {
    stageKey: typeof CREATE_CAMPAIGN_STAGES[number];
    isErrorOnTransition: boolean;
    isLoading: boolean;
  },
  createMode: typeof CREATE_CAMPAIGN_MODS[number];
  fields: CampaignCreateFormData;
  stepperSteps: readonly string[];
  activeStepperItem: typeof STEPPER_STEPS[number];
  visibleCards: {
    [card: string]: string[];
  }
};

export type CampaignState = {
  isLoading: boolean;
  error: boolean;
  campaignDataList: EntityState<CampaignInListData>;
  campaignData: CampaignData | null;
  sortBy: string | null;
  deletingCampaign: number | null;
  editingCampaign: number | null;
};

import {lazy, Suspense} from 'react';
import {Navigate, useLocation, useRoutes} from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';
import GuestGuard, {GuestRegisterGuard} from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
import CampaignEdit from '../pages/campaign/CampaignEdit';
import WidgetCreatePage from "../pages/widget/WidgetCreatePage";
import WidgetEditPage from "../pages/widget/WidgetEditPage";
import WidgetList from "../pages/widget/list/WidgetList";

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {pathname} = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const MyVideos = Loadable(lazy(() => import('../pages/video/MyVideoList')));
const AllReviews = Loadable(lazy(() => import('../pages/review/AllReviews')));
const LikedReviews = Loadable(lazy(() => import('../pages/review/LikedReviews')));
const DeletedReviews = Loadable(lazy(() => import('../pages/review/DeletedReviews')));
const ProfileSettings = Loadable(lazy(() => import('../pages/user/UserAccount')));
const TestimonialsCreator = Loadable(lazy(() => import('../pages/testimonials/Creator')));

export default function Router() {
  return useRoutes([
    // auth routes
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login/>
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestRegisterGuard>
              <Register/>
            </GuestRegisterGuard>
          )
        },
        {path: 'login-unprotected', element: <Login/>},
        {path: 'register-unprotected', element: <Register/>},
        {path: 'reset-password', element: <RestorePasswordRequest/>},
        {path: 'update-password', element: <UpdatePassword/>},
        // { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'verify', element: <VerifyCode /> }
      ]
    },
    {
      path: '/greetings',
      element: (
        <AuthGuard>
          <GreetingsPage/>
        </AuthGuard>)
    },
    {
      path: '/new-campaign',
      element: (
        <AuthGuard>
          <CampaignCreate/>
        </AuthGuard>)
    },
    {
      path: '/new-widget',
      element: (
        <AuthGuard>
          <WidgetCreatePage/>
        </AuthGuard>)
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout/>
        </AuthGuard>
      ),
      children: [
        {path: '/', element: <Navigate to="/dashboard" replace/>},
        {path: '/dashboard', element: <Dashboard/>},
        {path: '/my-videos', element: <MyVideos/>},
        {path: '/edit-campaign/:campaignId', element: <CampaignEdit/>},
        {path: '/edit-widget/:widgetId', element: <WidgetEditPage/>},
        {path: '/widgets', element: <WidgetList/>},
        {path: '/profile', element: < ProfileSettings/>},
        {path: '/campaigns', element: <CampaignList/>},
        {path: '/testimonials', element: <AllReviews/>},
        {path: '/liked-testimonials', element: <LikedReviews/>},
        {path: '/deleted-testimonials', element: <DeletedReviews/>},
        {path: '/referral-stats', element: <ReferralDashboard/>},
        {path: '/admin', element: <AdminPanel/>},
        {path: '/testimonials-creator', element: <TestimonialsCreator/>}
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout/>,
      children: [
        {path: '404', element: <NotFound/>},
        {path: '*', element: <Navigate to="/404" replace/>}
      ]
    },
    {path: '*', element: <Navigate to="/404" replace/>}
  ]);
}

const GreetingsPage = Loadable(lazy(() => import('../pages/GreetingsPage')));
const CampaignCreate = Loadable(lazy(() => import('../pages/campaign/CampaignCreate')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const CampaignList = Loadable(lazy(() => import('../pages/campaign/list/CampaignList')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ReferralDashboard = Loadable(lazy(() => import('../pages/referral/Dashboard')));
const AdminPanel = Loadable(lazy(() => import('../pages/admin/AdminPanel')));
const RestorePasswordRequest = Loadable(lazy(() => import('../pages/authentication/PasswordRestore')));
const UpdatePassword = Loadable(lazy(() => import('../pages/authentication/UpdatePassword')));

import {
  CampaignCreateFormData,
  CampaignCreateSlice,
  CampaignEditFormData,
  CREATE_CAMPAIGN_MODS,
  CREATE_CAMPAIGN_STAGES,
  STEPPER_STEPS
} from '../../@types/campaign';
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {RootState, store} from "../store";
import axios from "../../utils/axios";
import {reviewLink, wallLink} from '../../utils/campaignUtils';
import {useEffect} from "react";

const apiBase = '/api/v2';
const apiCampaignBase = `${apiBase}/campaigns`
const apiImagesBase = `${apiBase}/images`;

const initialState: CampaignCreateSlice = {
  createCampaignManager: {
    isLoading: false,
    error: null,
    data: null
  },
  uploadVideoManager: {
    isLoading: false,
    data: null,
    percent: 0
  },
  activeStage: {
    stageKey: CREATE_CAMPAIGN_STAGES[0],
    isErrorOnTransition: false,
    isLoading: false
  },
  stepperSteps: STEPPER_STEPS,
  activeStepperItem: STEPPER_STEPS[0],
  createMode: CREATE_CAMPAIGN_MODS[0],
  fields: {},
  uploadImageManager: {
    isLoading: false,
    data: null
  },
  skippedFields: [],
  visibleCards: {
    'campaign.content': [
        'campaign.content.lang',
        'campaign.content.linkText'
    ]
  }
};

const slice = createSlice({
  name: 'campaignCreate',
  initialState,
  reducers: {
    setInitialState(state) {
      state.activeStepperItem = initialState.activeStepperItem;
      state.createMode = initialState.createMode;
      state.createCampaignManager = {...initialState.createCampaignManager};
      state.uploadVideoManager = {...initialState.uploadVideoManager};
      state.activeStage = {...initialState.activeStage};
      state.skippedFields = [...initialState.skippedFields];
      state.uploadImageManager = {...initialState.uploadImageManager};
      state.visibleCards = {...initialState.visibleCards};
    },
    setInitialCampaignFormState(state) {
      state.activeStepperItem = initialState.activeStepperItem;
      state.createMode = initialState.createMode;
      state.activeStage = {...initialState.activeStage};
      state.introVideoId = undefined;
      state.introText = undefined;
    },
    setIntroVideoId(state, {payload}) {
      state.introVideoId = payload;
    },
    setIntroText(state, {payload}) {
      state.introText = payload;
    },
    setCreateCampaignStart(state) {
      state.createCampaignManager.isLoading = true;
    },
    setCreateCampaignFinish(state, { payload }) {
      state.createCampaignManager.isLoading = false;
      state.createCampaignManager.data = payload.data;
      if (payload.error !== null) {
        state.createCampaignManager.error = payload.error;
      }
    },
    setUploadVideoPercent(state, {payload}) {
      state.uploadVideoManager.percent = payload;
    },
    setUploadVideoData(state, { payload }) {
      state.uploadVideoManager.data = payload;
    },
    setUploadVideoLoadingState(state, { payload }) {
      state.uploadVideoManager.isLoading = payload;
    },
    setUploadImageLoadingState(state, { payload }) {
      state.uploadImageManager.isLoading = payload;
    },
    setUploadImageData(state, { payload }) {
      state.uploadImageManager.data = payload;
    },
    setActiveStepperItem(state, { payload }) {
      state.activeStepperItem = payload;
    },
    setSkippedField(state, { payload }) {
      if (!state.skippedFields.includes(payload)) {
        state.skippedFields = [...state.skippedFields, payload];
      }
    },
    setActiveStageTransitionStart(state, { payload }) {
      state.activeStage.stageKey = payload.stageKey;

      if (!payload.isImmediateTransition) { // needed for non-async transition
        state.activeStage.isLoading = true;
      }
    },
    setActiveStageTransitionStop(state, { payload }) {
      state.activeStage.isLoading = false;
      if (payload?.isErrorOnTransition) {
        state.activeStage.isErrorOnTransition = Boolean(payload.isErrorOnTransition);
      }
    },
    setCreateMode(state, { payload }) {
      state.createMode = payload;
    },
    setFields(state, { payload }) {
      state.fields = {
        ...state.fields,
        ...payload };
    },
    setVisibleCards(state, { payload }) {
      state.visibleCards = { ...payload };
    },
    setVisibleCard(state, { payload }) {
      if (state.visibleCards[payload.cardKey] === undefined) {
        state.visibleCards[payload.cardKey] = [payload.initStep];
      }
    },
    setVisibleStep(state, { payload }) {
      if (!state.visibleCards[payload.cardKey].includes(payload.stepKey)) {
        state.visibleCards[payload.cardKey].push(payload.stepKey);
      }
    }
  }
});

export const {
  setInitialState,
  setIntroVideoId,
  setIntroText,
  setUploadVideoPercent,
  setUploadVideoLoadingState,
  setUploadImageLoadingState,
  setActiveStepperItem,
  setActiveStageTransitionStart,
  setActiveStageTransitionStop,
  setCreateMode,
  setFields,
  setSkippedField,
  setVisibleCards,
  setVisibleCard,
  setVisibleStep,
  setInitialCampaignFormState
} = slice.actions;

export const createCampaign = (campaignData: CampaignCreateFormData) => async () => {
  const { dispatch } = store;

  dispatch(slice.actions.setCreateCampaignStart());

  try {
    const { data } = await axios.post(`${apiCampaignBase}/create`, campaignData);
    dispatch(slice.actions.setActiveStageTransitionStop({}));
    dispatch(slice.actions.setCreateCampaignFinish({
      data
    }));

    window.mixpanel?.track("created campaign");
  } catch (error) {
      throw error;
  }
};

export const updateCampaign = (campaignData: CampaignEditFormData) => async () => {
  const { dispatch } = store;

  dispatch(slice.actions.setCreateCampaignStart());

  try {
    const { data } = await axios.post(`${apiCampaignBase}/update`, campaignData);
    dispatch(slice.actions.setCreateCampaignFinish({
      data
    }));
    window.mixpanel?.track("updated campaign");
  } catch (error) {
    throw error;
  }
};

export const saveImage = (file: File, name: string) => async () => {
  const { dispatch } = store;
  dispatch(slice.actions.setUploadImageLoadingState(true));
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    const { data } = await axios.post(`${apiImagesBase}/create`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    });
    dispatch(slice.actions.setUploadImageData(data));
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getCampaignCreate = (state: RootState) => state.campaignCreate;

export const getActiveStageObj = (state: RootState) => getCampaignCreate(state).activeStage;
export const getActiveStageKey = (state: RootState) => getActiveStageObj(state).stageKey;
export const getActiveStageLoadingState = (state: RootState) => getActiveStageObj(state).isLoading;

export const getCreateCampaignManager = (state: RootState) => getCampaignCreate(state).createCampaignManager;
export const getCreateCampaignManagerData = (state: RootState) => getCreateCampaignManager(state).data;
export const getPublicCampaignLink = (state: RootState) => reviewLink(getCreateCampaignManagerData(state)?.publicId, state?.user?.userProfile?.customDomain);
export const getPublicWallLink = (state: RootState) => wallLink(getCreateCampaignManagerData(state)?.wallPublicId);

export const getUploadVideoManager = (state: RootState) => getCampaignCreate(state).uploadVideoManager;
export const getUploadVideoManagerProgress = (state: RootState) => getUploadVideoManager(state).percent;
export const getUploadVideoManagerLoadingState = (state: RootState) => getUploadVideoManager(state).isLoading;

export const getUploadImageManager = (state: RootState) => getCampaignCreate(state).uploadImageManager;
export const getUploadImageManagerId = (state: RootState) => getUploadImageManager(state).data?.imageId;
export const getUploadImageManagerIdLoadingState = (state: RootState) => getUploadImageManager(state).isLoading;
export const getIntroVideoId = (state: RootState) => getCampaignCreate(state).introVideoId;
export const getIntroText = (state: RootState) => getCampaignCreate(state).introText;

export const getActiveStepperItem = (state: RootState) => getCampaignCreate(state).activeStepperItem;
export const getStepperSteps = (state: RootState) => getCampaignCreate(state).stepperSteps;
export const getActiveStepperItemIndex = createSelector(
    [getStepperSteps, getActiveStepperItem],
    (steps, activeItem) => steps.indexOf(activeItem));

export const getCreateMode = (state: RootState) => getCampaignCreate(state).createMode;
export const getFields = (state: RootState) => getCampaignCreate(state).fields;

export const getVisibleCards = (state: RootState) => getCampaignCreate(state).visibleCards;
export const getVisibleCardsKeys = createSelector(
    [getVisibleCards],
    cards => Object.keys(cards));
export const getVisibleSteps = createSelector(
    [
      getVisibleCards,
      (state: RootState, cardKey: string) => cardKey
    ],
    (cards, cardKey) => cards[cardKey]);

export const getVisibleCardsCount = (state: RootState) => getVisibleCardsKeys(state).length;
export const getIsLastCardRendered = createSelector(
    [
      getVisibleCardsCount,
      (state: RootState, availableCardsCount: number) => availableCardsCount
    ],
    (visibleCardsCount, availableCardsCount) => visibleCardsCount === availableCardsCount
);

export const getSkippedFields = (state: RootState) => getCampaignCreate(state).skippedFields;

export const getIsFieldSkipped = createSelector(
    [getSkippedFields, (state: RootState, fieldName: string) => fieldName],
    (skippedFields, fieldName) => {
      return skippedFields.includes(fieldName);
    }
);

export default slice.reducer;


import {Form, FormikProvider, useFormik} from 'formik';
import {Button, Card, CardHeader, Stack, Typography} from '@material-ui/core';

import useLocales from "../../hooks/useLocales";
import React, {ReactNode, useEffect, useState} from "react";
import RecordVideoDialog from "../../components/forms/RecordVideoDialog";
import SelectVideoDialog from "../../components/forms/SelectVideoDialog";
import {styled} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {getVideoList, saveVideo, selectAllVideos} from "../../redux/slices/videos";

import {isBrowser, isMobile} from 'react-device-detect';
import {RootState, useSelector} from "../../redux/store";
import {getProfile} from "../../redux/slices/user";
import {
    setCreateMode,
    setUploadVideoLoadingState,
    setUploadVideoPercent,
    setVideoId
} from "../../redux/slices/widgetCreate";
import {CREATE_WIDGET_MODS} from "../../@types/widget";
import {useSnackbar} from "notistack5";

type IntroPageProps = {
    nextStage: () => any;
};

const Input = styled('input')({
    display: 'none'
});

const StyledButton = styled(Button)({
    borderRadius: 50,
    minHeight: 45,
    textTransform: 'none',
    backgroundColor: '#fff',
    borderWidth: 0,
    '&:hover': {
        backgroundColor: '#5069f6',
        color: '#fff'
    }
});

const DownloadButton = ({children}: { children: ReactNode }) => (<Button
  sx={{
      width: '100%',
      borderRadius: 50,
      minHeight: 45,
      textTransform: 'none',
      textAlign: 'center',
      backgroundColor: '#fff',
      borderWidth: 0,
      '&:hover': {
          backgroundColor: '#5069f6',
          color: '#fff'
      }
  }}
  component="span"
  variant="outlined"
>
    {children}
</Button>);

export default function WidgetIntroPage({nextStage}: IntroPageProps) {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {products: null},
        onSubmit: async (values, {setErrors, setSubmitting}) => {
            try {
                setSubmitting(true);
                nextStage();
            } catch (error) {
                console.error(error);
                setErrors(error.message);
            }
        }
    });

    const {values, handleSubmit} = formik;
    const {translate} = useLocales();
    const videoId = useSelector((state: RootState) => state.widgetCreate.videoId);
    const [openRecordVideoDialog, setOpenRecordVideoDialog] = useState(false);
    const [openSelectVideoDialog, setOpenSelectVideoDialog] = useState(false);
    const [openUploadVideoDialog, setOpenUploadVideoDialog] = useState(false);
    const dispatch = useDispatch();

    const videos = useSelector(selectAllVideos);
    const {userProfile} = useSelector(
      (state: RootState) => state.user
    );

  const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getVideoList());
    }, [dispatch]);

    if (!videos || !userProfile) {
        return null;
    }

    let maxWidth = '800px'

    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length && files[0].size < 500 * 1024 * 1024) {
            dispatch(setUploadVideoLoadingState(true));
            nextStage();

            const result = await dispatch(saveVideo(files[0], files[0].name, (newValue) => {
                dispatch(setUploadVideoPercent(newValue))
            }));
            if (result) {
                dispatch(setUploadVideoLoadingState(false));
                dispatch(setVideoId(result))
            }
        } else {
          enqueueSnackbar("Video is too big", {variant: 'error'});
        }
    };

    return (
      <>
          <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{margin: 'auto', maxWidth: maxWidth}}>
                  <Card
                    sx={{
                        mb: 3,
                        pb: {
                            lg: 10,
                            xs: 5
                        },
                        px: {
                            xs: 1,
                            md: 5
                        }
                    }}>
                      <CardHeader
                        title={
                            <Typography variant="h3" align={'center'}
                                        style={{color: '#4965c8', whiteSpace: 'pre-wrap'}}>
                                {translate("widget.intro.header")}
                            </Typography>
                        }
                        sx={{mb: 3}}
                      />
                      <Card sx={{
                          px: {
                              lg: 3,
                              xs: 1
                          }, mx: {
                              lg: 3,
                              xs: 1
                          },
                          pb: 5,
                          mb: 5,
                          maxWidth: 400,
                      }} style={{
                          justifyContent: 'center',
                          background: '#f4f6f8',
                          margin: 'auto',
                          marginBottom: '10px'
                      }}>
                          <CardHeader
                            title={
                                <Typography align={'center'} style={{color: '#434b66', padding: 0}}>
                                    {translate("widget.intro.options.description")}
                                </Typography>
                            }
                            sx={{mb: 3, px: 0, pt: 3}}
                          />
                          <Stack spacing={3}>
                              {isBrowser && (<StyledButton
                                variant="outlined"
                                onClick={() => setOpenRecordVideoDialog(true)}
                              >
                                  {translate("widget.intro.options.newVideo")}
                              </StyledButton>)}
                              {isMobile && (<label htmlFor="upload-new-video">
                                  <Input accept="video/*" capture type="file" id="upload-new-video"
                                         onChange={handleInputChange}
                                         onClick={() => setOpenUploadVideoDialog(true)}/>
                                  <DownloadButton>
                                      {translate("widget.intro.options.newVideo")}
                                  </DownloadButton>
                              </label>)}
                              <label htmlFor="upload-existing-video">
                                  <Input accept="video/*" type="file" id="upload-existing-video"
                                         onChange={handleInputChange}
                                         onClick={() => setOpenUploadVideoDialog(true)}/>
                                  <DownloadButton>
                                      {translate("campaign.intro.options.useExisting")}
                                  </DownloadButton>
                              </label>
                              {videos.length > 0 && <StyledButton
                                variant='outlined'
                                onClick={() => setOpenSelectVideoDialog(true)}
                              >
                                  {translate("widget.intro.options.myVideo")}
                              </StyledButton>}
                          </Stack>
                      </Card>
                  </Card>
              </Form>
          </FormikProvider>
          <RecordVideoDialog
            nextStage={nextStage}
            open={openRecordVideoDialog}
            setOpen={setOpenRecordVideoDialog}
            setUploadingLoadingState={(value: boolean) => dispatch(setUploadVideoLoadingState(value))}
            onRecorded={() => {
            }}
            setRecordedVideoId={(id: any) => dispatch(setVideoId(id))}
            setUploadPercent={(value: number) => dispatch(setUploadVideoPercent(value))}
          />
          <SelectVideoDialog
            nextStage={nextStage}
            open={openSelectVideoDialog}
            setOpen={setOpenSelectVideoDialog}
            onVideoSelectConfirm={() => {
                dispatch(setCreateMode(CREATE_WIDGET_MODS[1]));
            }}
            selectedVideoId={videoId}
            onVideoSelect={(id) => {
                dispatch(setVideoId(id));
            }}/>
      </>
    );
}

import {Icon} from '@iconify/react';
import * as React from 'react';
import {useEffect, useState} from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import linkChain from '@iconify/icons-akar-icons/link-chain';
import {Link as RouterLink} from 'react-router-dom';
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import {RootState, useDispatch, useSelector} from '../../../redux/store';

import {PATH_DASHBOARD} from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import {WidgetInListData} from '../../../@types/widget';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import useLocales from "../../../hooks/useLocales";
import {useSnackbar} from "notistack5";
import CopyToClipboardCust from "../../../components/misc/CustomCopyToClipboard";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import {Grid, Link} from '@mui/material';
import thinkingFace from "@iconify/icons-noto/thinking-face";
import {getProfile} from "../../../redux/slices/user";
import ListHead from "../../../components/list/ListHead";
import Status from "../../../components/list/Status";
import {changeEnableStatus, deleteWidget, getWidgetList, selectAllWidgets} from "../../../redux/slices/widgets";
import WidgetMoreMenu from "../components/WidgetMoreMenu";
import {formatStats, widgetCode} from "../../../utils/widgetUtils";

// ----------------------------------------------------------------------


type Anonymous = Record<string | number, string>;

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
  array: WidgetInListData[],
  comparator: (a: any, b: any) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function WidgetList() {
  const {themeStretch} = useSettings();
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const mixpanel = window.mixpanel;

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (widgetId: number) => {
    const success = await dispatch(deleteWidget(widgetId))
    if (success) {
      enqueueSnackbar(translate('widget.messages.deleteSuccess'), {variant: 'success'})
    }
  };

  const widgetList = useSelector(selectAllWidgets)
  const isLoading = useSelector((state: RootState) => state.widget.isLoading)
  const {userProfile} = useSelector((state: RootState) => state.user);
  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getWidgetList());
  }, [dispatch]);
  const {translate} = useLocales();
  const {enqueueSnackbar} = useSnackbar();

  const [deletingWidget, setDeletingWidget] = React.useState(-1);

  const handleDeleteOpen = (id: number) => {
    setDeletingWidget(id);
  };

  const handleDeleteClose = () => {
    setDeletingWidget(-1);
  };

  const handleWidgetDelete = () => {
    if (deletingWidget !== -1) {
      handleDelete(deletingWidget)
    }
    handleDeleteClose();
  };

  useEffect(() => {
    if (userProfile) {
      mixpanel?.track('widget list open', {
        uid: userProfile.id,
      });
    }
  }, [userProfile != null]);

  if (!widgetList || !userProfile) {
    return null;
  }

  const hasAnyWidgets = widgetList.length > 0;

  const sortedWidgets = applySortFilter(widgetList, getComparator(order, orderBy));

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sortedWidgets.length) : 0;

  let tableHeader = [
    {id: 'name', label: translate('widget.list.fields.name'), alignRight: false},
    {id: 'ctaClickPercent', label: translate('widget.list.fields.ctaClickPercent'), alignRight: false},
    {id: 'threeQuartileViewPercent', label: translate('widget.list.fields.threeQuartileViewPercent'), alignRight: false},
    {id: 'enabled', label: translate('widget.list.fields.status'), alignRight: false},
    {id: 'codeButton', label: translate('widget.list.fields.codeButton')},
    {id: 'moreOptions', label: ''}
  ];

  return (
    <Page title={translate('mainMenu.items.widgetList')}>
      <Dialog
        open={deletingWidget !== -1}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate('widget.action.delete.confirmText')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteClose}>{translate('widget.action.delete.cancel')}</Button>
          <Button onClick={handleWidgetDelete} autoFocus>
            {translate('widget.action.delete.ok')}
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={translate('pages.widgets')}
          links={[]}
          action={
            hasAnyWidgets ? <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.general.createWidget}
              startIcon={<Icon icon={plusFill}/>}
              onClick={() => {
                mixpanel?.track('create widget from widget list', {
                  uid: userProfile.id,
                });
              }
              }
            >
              {translate('widget.list.createWidgetButton')}
            </Button> : null
          }
        />

        {/*no widgets block*/}
        {!isLoading && !hasAnyWidgets &&
            <Grid container>
                <Card>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                    >
                        <Grid item sx={{mx: {xs: '0', md: 12}}}>
                            <Typography variant="h4" sx={{px: 2, mb: 3, mt: 3, mx: 2, textAlign: 'center'}}>
                              {translate('widget.list.texts.noWidgetsYetHeader')}
                                <Icon style={{marginLeft: 6, height: '24px', marginBottom: '-4px'}}
                                      icon={thinkingFace}/>
                            </Typography>
                            <Typography variant="subtitle1" sx={{px: 2, mb: 3, mt: 3, mx: 2, textAlign: 'center'}} style={{ whiteSpace: "pre-wrap"}}>
                              {translate('widget.list.texts.noWidgetYetText')}
                                <a target={"_blank"} href={translate('widget.list.texts.noWidgetYetLink')}>
                                  {translate('widget.list.texts.noWidgetYetLinkText')}
                                </a>
                            </Typography>
                        </Grid>
                        <Grid>
                            <Button
                                sx={{px: 2, mb: 3, mx: 5}}
                                variant="contained"
                                component={RouterLink}
                                to={PATH_DASHBOARD.general.createWidget}
                                startIcon={<Icon icon={plusFill}/>}
                            >
                              {translate('widget.list.createWidgetButton')}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        }

        {/*widget list*/}
        {hasAnyWidgets && <Card>
            <Scrollbar>
                <TableContainer sx={{minWidth: 800}}>
                    <Table>
                        <ListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={tableHeader}
                            rowCount={sortedWidgets.length}
                            numSelected={0}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={(checked: boolean) => {
                            }}
                        />
                        <TableBody>
                          {sortedWidgets
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                              const {id, publicId, name, enabled, ctaClickPercent, threeQuartileViewPercent, containerType} = row;

                              return (
                                <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                >
                                  <TableCell component="th" scope="row" padding="none" sx={{maxWidth: '500px'}}>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Link component={RouterLink} to={`${PATH_DASHBOARD.root}/edit-widget/${id}`}
                                            noWrap>
                                        <Typography variant="subtitle2" noWrap>
                                          {name}
                                        </Typography>
                                      </Link>
                                    </Stack>
                                  </TableCell>
                                  <TableCell align="left">{formatStats(ctaClickPercent)}</TableCell>
                                  <TableCell align="left">{formatStats(threeQuartileViewPercent)}</TableCell>
                                  <TableCell align="left">
                                    <Status
                                      enabled={enabled}
                                      entityId={id}
                                      enabledText={translate('widget.list.status.enabled')}
                                      disabledText={translate('widget.list.status.disabled')}
                                      enableStateChangedText={translate('widget.messages.enableStateChanged')}
                                      changeEntityStatus={async (entityId: number, enabled: boolean) => {
                                        return await dispatch(changeEnableStatus(entityId, enabled));
                                      }
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <CopyToClipboardCust>
                                      {({copy}) => (
                                        <Button
                                          variant="outlined"
                                          startIcon={<Icon icon={linkChain}/>}
                                          onClick={() => copy(widgetCode(publicId, containerType === 'embedded'))}
                                        >
                                          {translate('widget.action.copyLink')}
                                        </Button>
                                      )}
                                    </CopyToClipboardCust>
                                  </TableCell>
                                  <TableCell align="right">
                                    <WidgetMoreMenu
                                      onDelete={() => handleDeleteOpen(id)}
                                      id={id}/>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{height: 53 * emptyRows}}>
                              <TableCell colSpan={6}/>
                            </TableRow>
                          )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>

            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={sortedWidgets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={(e: any) => {
                  handleChangeRowsPerPage(e);
                }}
            />
        </Card>}
      </Container>
    </Page>
  );
}

import {useEffect} from "react";
import {useLocation} from "react-router-dom";

declare global {
  interface Window {
    chatwootSettings: any;
    $chatwoot: any;
  }
}

export default function ChatbotManager() {
  let location = useLocation();

  const hideChatBubble = function(hideChatbot: boolean) {
    let elements = document.getElementsByClassName('woot-widget-bubble');
    [].forEach.call(elements, function (el) {
      let element = el as any;
      element['style']['display'] = hideChatbot ? 'none' : 'initial';
    });
  }

  const handleBubbleVisibility = function() {
    const hideChatbot = location.pathname.includes("/new-campaign") || location.pathname.includes("/new-widget");

    if (window.$chatwoot) {
      hideChatBubble(hideChatbot);
    } else {
      setTimeout(() => {
        hideChatBubble(hideChatbot);
      }, 1500)
    }
  }

  useEffect(
    () => {
      handleBubbleVisibility();
    },
    [location]
  )

  return null;
}

import {createSlice} from '@reduxjs/toolkit';
import {dispatch, store} from '../store';
// utils
import axios from '../../utils/axios';
import {NotificationSettings, PaymentDetails, SimpleResponse, User} from '../../@types/user';

// ----------------------------------------------------------------------

type UserState = {
  isLoading: boolean;
  error: boolean;
  userProfile: null | User;
  paymentDetails: null | PaymentDetails;
  notifications: NotificationSettings | null;
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  userProfile: null,
  notifications: null,
  paymentDetails: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.userProfile = action.payload;
    },

    getPaymentDetailsSuccess(state, action) {
      state.isLoading = false;
      state.paymentDetails = action.payload;
    },

    cancelPlanSuccess(state) {
      state.isLoading = false;
    },

    redeemAppsumoCodeSuccess(state) {
      state.isLoading = false;
    },
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProfile() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v2/users/get');
      await dispatch(slice.actions.getProfileSuccess(response.data));
      return true
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false
    }
  };
}

// ----------------------------------------------------------------------

export function getPaymentDetails() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/payments/subscription-details');
      dispatch(slice.actions.getPaymentDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function cancelPlan() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/payments/cancel-subscription');
      dispatch(slice.actions.cancelPlanSuccess());
      dispatch(getPaymentDetails())
      return true
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false
    }
  };
}

export function redeemAppsumoCode(code: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/payments/redeem-appsumo-code', {code});
      const success = response.data && response.data['success'] === true;
      dispatch(slice.actions.redeemAppsumoCodeSuccess());
      return success;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false
    }
  };
}

export function saveUser(user: User) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { user: User } } = await axios.patch('/api/v1/users/update', user);
      await dispatch(getProfile());
      return true
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function promoteToReferral(email: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: { resp: SimpleResponse } } =  await axios.put('/api/admin/users/promote-to-referral/' + encodeURI(email))
      return response.data
    } catch (err) {
      console.error(err);
      dispatch(slice.actions.hasError(err));
      return {success: false, error: err} as SimpleResponse;
    }
  };
}

export function setLoadedUser(user: User) {
  const { dispatch } = store;
  return dispatch(slice.actions.getProfileSuccess(user));
}


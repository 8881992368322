import * as yup from 'yup';
import {useSelector} from 'react-redux';

import {Form, FormikProvider, useFormik} from 'formik';

import {styled} from "@mui/material/styles";
import StepContent from '@mui/material/StepContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';

import {useSnackbar} from 'notistack5';

import StepCard from '../../components/forms/StepCard';

import useLocales from "../../hooks/useLocales";
import {CREATE_WIDGET_MODS, WidgetCreateFormData, WidgetData} from '../../@types/widget';
import React, {useEffect, useState} from "react";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Page from "../../components/Page";
import {Container} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import CampaignField from "../../components/forms/CustomField";
import {useNavigate} from "react-router-dom";
import {dispatch, RootState} from "../../redux/store";
import Box from "@mui/material/Box";
import OverlayButton from "../video/components/OverlayButton";
import CustomRadio from "../../components/forms/CustomRadio";
import CtaDelaySlider from "./components/CtaDelaySlider";
import ColorPickerField from "../../components/forms/ColorPickerField";
import {
    createWidget,
    getCreateMode,
    getUploadVideoManagerLoadingState,
    getUploadVideoManagerProgress
} from 'redux/slices/widgetCreate';
import LinearProgressWithLabel from "../../components/upload/LinearProgressWithLabel";
import {getWidgetData, updateWidget} from "../../redux/slices/widgets";

const validationSchema = yup.object().shape({
    name: yup.string().required('widget.content.name.validation'),
});

export type WidgetFieldsProps = {
    name?: string;
    ctaText?: string;
    unmuteText?: string;
    containerType?: string;
    position?: string;
    ctaDelay?: number;
    ctaLink?: string;
    ctaColor?: string;
    highlightColor?: string;
    closeColor?: string;
};

const StyledButton = styled(OverlayButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    border: '1px solid transparent',
    '&:hover': {
        border: '1px solid #fff',
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    }
}));

const StyledConfirmButton = styled(StyledButton)(({ theme }) => ({
    color: '#fff',
    borderColor: 'transparent',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: '#fff',
        border: '1px solid ' + theme.palette.primary.main,
        color: theme.palette.primary.main,
    }
}));

type WidgetFormPageProps = {
    nextStage: () => void;
    editingWidgetId?: number;
};

export const StyledStepContent = styled(StepContent)(({ theme }) => ({
    color: theme.palette.grey['500']
}));

const WidgetForm = ({ nextStage, editingWidgetId }: WidgetFormPageProps) => {
    const { themeStretch } = useSettings();
    const { translate } = useLocales();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const videoIsLoadingState = useSelector(getUploadVideoManagerLoadingState);
    const videoLoadingProgress = useSelector(getUploadVideoManagerProgress);
    const [submitted, setSubmitted] = useState(false);
    const [blockSubmission, setBlockSubmission] = useState(false);
    const currentCreateMode = useSelector(getCreateMode);
    const isEdit = editingWidgetId !== undefined && editingWidgetId > 0;
    const editingWidgetData = useSelector((state: RootState) => state.widget.widgetData);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (isEdit) {
            dispatch(getWidgetData(editingWidgetId!))
        }
    }, [dispatch])

    useEffect(() => {
        setInitialized(true);
        console.log("initialized widget data")
    }, [editingWidgetData])

    const isWithoutVidMode = isEdit || currentCreateMode === CREATE_WIDGET_MODS[1];
    const videoId = useSelector((state: RootState) => state.widgetCreate.videoId);

    const formik = useFormik<WidgetFieldsProps>({
        enableReinitialize: true,
        initialValues: {
            name: isEdit ? (editingWidgetData?.name || '') : '',
            ctaText: isEdit ? (editingWidgetData?.ctaText || '') : translate('widget.cta.ctaText.placeholder'),
            unmuteText: isEdit ? (editingWidgetData?.unmuteText || '') : translate('widget.content.unmuteText.placeholder'),
            ctaDelay: isEdit ? (editingWidgetData?.ctaDelay || 5) : 5,
            ctaLink: isEdit ? (editingWidgetData?.ctaLink || '') : '',
            highlightColor: isEdit ? (editingWidgetData?.highlightColor || '#fcfbf0') : '#fcfbf0',
            closeColor: isEdit ? (editingWidgetData?.closeColor || '#fcfbf0') : '#fcfbf0',
            position: (isEdit ? (editingWidgetData?.position ?? '') : 'right'),
            containerType: (isEdit ? (editingWidgetData?.containerType ?? '') : 'popup'),
            ctaColor: (isEdit ? (editingWidgetData?.ctaColor ?? '#2567f9') : '#2567f9'),
        },
        validationSchema: validationSchema,
        onSubmit: async (values, {setErrors, setSubmitting}) => {
            try {
                const performedData = {
                    ...values,
                };

                const formData: WidgetCreateFormData = {
                    name: performedData.name,
                    ctaText: performedData.ctaText,
                    unmuteText: performedData.unmuteText,
                    containerType: performedData.containerType,
                    position: performedData.position,
                    ctaDelay: performedData.ctaDelay,
                    ctaLink: performedData.ctaLink,
                    ctaColor: performedData.ctaColor,
                    highlightColor: performedData.highlightColor,
                    closeColor: performedData.closeColor,
                    videoId: videoId,
                };

                if (videoIsLoadingState && !isWithoutVidMode) {
                    window.scrollTo(0, 0);
                    setSubmitted(true);
                    setSubmitting(false);
                    return;
                }

                setSubmitting(true);
                if (isEdit) {
                    const editSuccess = await dispatch(updateWidget({
                        ...formData,
                        id: editingWidgetId!
                    } as WidgetData));
                    if (editSuccess) {
                        enqueueSnackbar(translate('widget.messages.editSuccess'), {variant: 'success'});
                        navigate('/widgets');
                    } else {
                        enqueueSnackbar(translate('widget.messages.editFail'), {variant: 'error'});
                    }
                } else {
                    await dispatch(createWidget(formData));
                    nextStage();
                }

                setSubmitting(false);
            } catch (error) {
                console.error(error);
                setErrors(error.message);
            }
        }
    });

    const { handleSubmit, submitForm } = formik;

    const VideoProgressCard = ({ progress, isLoading }: {
        progress: number;
        isLoading: boolean
    }) => {
        const { translate } = useLocales();
        const cardTitle = translate('campaign.videoDownload.progress');
        const completedCardTitle = translate('campaign.videoDownload.completed')

        useEffect(() => {
            if (submitted && !isLoading && !blockSubmission) {
                setBlockSubmission(true);
                submitForm();
            }
        }, [submitted, isLoading]);

        const progressValue = isLoading ? Math.min(99, progress) : progress

        return (
          <StepCard title={isLoading ? cardTitle : completedCardTitle}>
              <LinearProgressWithLabel value={progressValue} />
          </StepCard>
        );
    }

    return (
        <Page title={translate('widget.create.header')}>
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{
                px: 0, mx: 0
            }}>
                {!isWithoutVidMode && <VideoProgressCard
                    isLoading={videoIsLoadingState}
                    progress={videoLoadingProgress}
                  />}
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <StepCard title={translate('widget.content.header')}>
                            <Stepper orientation="vertical">
                                <Step key='widget.content.name' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`widget.content.name.header`)}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
                                            {translate(`widget.content.name.subtitle`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CampaignField
                                          inputId="name"
                                          mergeInputProps={{
                                              placeholder: translate('widget.content.name.placeholder')
                                          }}
                                        />
                                    </StyledStepContent>
                                </Step>

                                <Step key='widget.content.containerType' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate('widget.content.containerType.header')}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
                                            {translate(`widget.content.containerType.subtitle`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CustomRadio
                                          fieldName="containerType"
                                          options={
                                              [
                                                  {
                                                      value: 'popup',
                                                      label: translate(`widget.content.containerType.popupOption`)
                                                  },
                                                  {
                                                      value: 'embedded',
                                                      label: translate(`widget.content.containerType.embeddedOption`)
                                                  }
                                              ]
                                          }
                                        />
                                    </StyledStepContent>
                                </Step>

                                {formik?.values?.containerType === 'popup' && <Step key='widget.content.position' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate('widget.content.position.header')}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
                                            {translate(`widget.content.position.subtitle`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CustomRadio
                                          fieldName="position"
                                          options={
                                              [
                                                  {
                                                      value: 'left',
                                                      label: translate(`widget.content.position.leftOption`)
                                                  },
                                                  {
                                                      value: 'right',
                                                      label: translate(`widget.content.position.rightOption`)
                                                  }
                                              ]
                                          }
                                        />
                                    </StyledStepContent>
                                </Step>}
                            </Stepper>
                        </StepCard>

                        <StepCard title={translate('widget.cta.header')}>
                            <Stepper orientation="vertical" sx={{overflow: 'visible'}}>
                                <Step key='widget.cta.ctaText' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`widget.cta.ctaText.header`)}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
                                            {translate(`widget.cta.ctaText.subtitle`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CampaignField
                                          inputId="ctaText"
                                          mergeInputProps={{
                                              placeholder: translate('widget.cta.ctaText.placeholder')
                                          }}
                                        />
                                    </StyledStepContent>
                                </Step>

                                <Step key='widget.cta.ctaLink' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`widget.cta.ctaLink.header`)}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
                                            {translate(`widget.cta.ctaLink.subtitle`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CampaignField
                                          inputId="ctaLink"
                                        />
                                    </StyledStepContent>
                                </Step>

                                <Step key='widget.cta.ctaDelay' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`widget.cta.ctaDelay.header`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CtaDelaySlider fieldName="ctaDelay" />
                                    </StyledStepContent>
                                </Step>
                            </Stepper>
                        </StepCard>

                        <StepCard title={translate('widget.appearance.header')} sx={{overflow: 'visible'}}>
                            <Stepper orientation="vertical" sx={{paddingBottom: '50px'}}>
                                <Step key={'widget.appearance.ctaColor' + formik.initialValues.ctaColor} active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`widget.appearance.ctaColor.header`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <ColorPickerField fieldName="ctaColor"/>
                                    </StyledStepContent>
                                </Step>

                                <Step key={'widget.appearance.highlightColor'+formik.initialValues.highlightColor} active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`widget.appearance.highlightColor.header`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <ColorPickerField fieldName="highlightColor"/>
                                    </StyledStepContent>
                                </Step>

                                <Step key={'widget.appearance.closeColor'+formik.initialValues.closeColor} active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`widget.appearance.closeColor.header`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <ColorPickerField fieldName="closeColor"/>
                                    </StyledStepContent>
                                </Step>

                                <Step key='widget.content.unmuteText' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`widget.content.unmuteText.header`)}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
                                            {translate(`widget.content.unmuteText.subtitle`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CampaignField
                                          inputId="unmuteText"
                                          mergeInputProps={{
                                              placeholder: translate('widget.content.unmuteText.placeholder')
                                          }}
                                        />
                                    </StyledStepContent>
                                </Step>
                            </Stepper>
                        </StepCard>

                        <Box display="flex" sx={{ width: '100%', justifyContent: 'center' }}>
                            <StyledButton onClick={() => navigate('/widgets')} sx={{ mr: 3 }}>
                                {translate(isEdit ? 'widget.action.edit.cancel' : 'widget.action.create.cancel')}
                            </StyledButton>
                            <StyledConfirmButton type="submit">
                                {translate(isEdit ? 'widget.action.edit.ok' : 'widget.action.create.ok')}
                            </StyledConfirmButton>
                        </Box>
                    </Form>
                </FormikProvider>
            </Container>
        </Page>
    );
};

export default WidgetForm;

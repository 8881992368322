// material
import {visuallyHidden} from '@material-ui/utils';
import {Box, TableCell, TableHead, TableRow, TableSortLabel} from '@material-ui/core';

// ----------------------------------------------------------------------

type ListHeadProps = {
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headLabel: any[];
  numSelected: number;
  onRequestSort: (id: string) => void;
  onSelectAllClick: (checked: boolean) => void;
};

export default function ListHead({
                                           order,
                                           orderBy,
                                           headLabel,
                                           onRequestSort,
                                         }: ListHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => onRequestSort(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{...visuallyHidden}}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

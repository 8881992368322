import * as Yup from 'yup';
import {useState} from 'react';
import {Form, FormikProvider, useFormik} from 'formik';
import {Icon} from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {Alert, IconButton, InputAdornment, Stack, TextField} from '@material-ui/core';
import {LoadingButton} from '@material-ui/lab';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import useLocales from "../../../hooks/useLocales";

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};

// TODO selected location doesn't work after logout
export default function LoginForm() {
  const {login} = useAuth();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const {translate} = useLocales();

  const invalidEmailFormatMessage = translate("auth.validation.email.wrongFormat")
  const missingEmailMessage = translate("auth.validation.email.missing")
  const missingPasswordMessage = translate("auth.validation.password.missing")

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(invalidEmailFormatMessage).required(missingEmailMessage),
    password: Yup.string().required(missingPasswordMessage)
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, {setErrors, setSubmitting, resetForm}) => {
      try {
        const loginResult = await login(values.email, values.password);
        if (!loginResult.success) {
          let errorMessage = loginResult.authFailed ?
            translate('auth.login.error.invalidCredentials') :
            translate('auth.login.error.generalError')

          setErrors({afterSubmit: errorMessage});
        }
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        let errorMessage = error.message ? error.message : translate('auth.login.error.generalError')
        setErrors({afterSubmit: errorMessage});
      }
    }
  });

  const {errors, touched, values, isSubmitting, handleSubmit, getFieldProps} = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label={translate('auth.field.email')}
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label={translate('auth.field.password')}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill}/>
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {translate('auth.login.button.login')}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}

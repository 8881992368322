import React from 'react';

import { useField } from 'formik';
import {styled} from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {CustomOption} from "./CustomRadioOptions";

type CampaignRadioProps = {
    fieldName: string;
    options: CustomOption[];
    radioGroupProps?: RadioGroupProps;
};

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    paddingRight: theme.spacing(5)
}));

const CustomRadio = ({ fieldName, options, radioGroupProps }: CampaignRadioProps) => {
    const [field] = useField(fieldName);

    return (<RadioGroup {...field} {...radioGroupProps} row aria-label={fieldName}>
        {options.map(option => (
            <StyledFormControlLabel key={option.value} value={option.value} control={<Radio sx={{
                color: '#7291FC',
                '&.Mui-checked': {
                    color: '#7291FC',
                },
            }}/>} label={option.label} />
        ))}
    </RadioGroup>);
};

export default CustomRadio;

// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/';
const ROOTS_AUTH = '/auth';
const GREETINGS_PAGE = '/greetings';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  // verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  greetings: GREETINGS_PAGE,
  general: {
    myVideos: path(ROOTS_DASHBOARD, "/my-videos"),
    createWidget: path(ROOTS_DASHBOARD, "/new-widget"),
    editWidget: path(ROOTS_DASHBOARD, "/edit-widget"),
    widgetList: path(ROOTS_DASHBOARD, "/widgets"),
    createVideo: path(ROOTS_DASHBOARD, "/new-video"),
    createCampaign: path(ROOTS_DASHBOARD, "/new-campaign"),
    campaignList: path(ROOTS_DASHBOARD, "/campaigns"),
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),

    reviewsRoot: path(ROOTS_DASHBOARD, '/root-testimonials'),
    allReviews: path(ROOTS_DASHBOARD, '/testimonials'),
    likedReviews: path(ROOTS_DASHBOARD, '/liked-testimonials'),
    deletedReviews: path(ROOTS_DASHBOARD, '/deleted-testimonials'),

    referral: path(ROOTS_DASHBOARD, '/referral-stats'),

    admin: path(ROOTS_DASHBOARD, "/admin"),

    testimonialsCreator: path(ROOTS_DASHBOARD, '/testimonials-creator'),
  }
};

import {Comparer, createSlice} from '@reduxjs/toolkit';
import {RootState, store} from '../store';
import axios from '../../utils/axios';
import compareDesc from 'date-fns/compareDesc';
import {WidgetData, WidgetInListData, WidgetState} from "../../@types/widget";
import {createWidgetsAdapter} from "../utils";

const apiBase = "/api/v2/widgets"

const byCreateDateComparator: Comparer<WidgetInListData> = (a, b) => compareDesc(a.createdAt, b.createdAt);
const widgetsAdapter = createWidgetsAdapter(byCreateDateComparator);

const initialState: WidgetState = {
  isLoading: false,
  error: false,
  widgetDataList: widgetsAdapter.getInitialState(),
  widgetData: null,
  sortBy: null,
  deletingWidget: null,
  editingWidget: null
};

const slice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getWidgetListSuccess(state, action) {
      state.isLoading = false;
      widgetsAdapter.setAll(state.widgetDataList, action.payload);
    },

    getWidgetSuccess(state, action) {
      state.isLoading = false;
      state.widgetData = action.payload;
    },

    onWidgetInListOperationSuccess(state) {
      state.isLoading = false;
    },

    updateWidget(state, action) {
      widgetsAdapter.updateOne(state.widgetDataList, action.payload);
    },

    setWidgetDelete(state, action) {
      state.deletingWidget = action.payload;
    },

    setWidgetEdit(state, action) {
      state.editingWidget = action.payload;
    },

    deleteWidgetFromState(state, action) {
      state.deletingWidget = null;
      widgetsAdapter.removeOne(state.widgetDataList, action.payload);
    },

    sortByWidget(state, action) {
      state.sortBy = action.payload;
    },
    setWidgetData(state, {payload}) {
      state.widgetData = payload;
    }
  }
});

export default slice.reducer;

export const {
  sortByWidget,
  setWidgetDelete
} = slice.actions;

export function getWidgetList() {
  return async () => {
    const {dispatch} = store;
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: WidgetInListData[] } = await axios.get(apiBase + "/all");
      dispatch(slice.actions.getWidgetListSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteWidget(id: number) {
  return async () => {
    const {dispatch} = store;
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(apiBase + '/delete/' + id);
      dispatch(slice.actions.onWidgetInListOperationSuccess());
      dispatch(slice.actions.deleteWidgetFromState(id));
      dispatch(getWidgetList());
      return true;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function changeEnableStatus(id: number, enabled: boolean) {
  return async () => {
    const {dispatch} = store;
    dispatch(slice.actions.startLoading());
    try {
      const patch = {
        "id": id,
        "enabled": enabled
      }
      await axios.post(apiBase + '/update-raw', patch);
      dispatch(slice.actions.onWidgetInListOperationSuccess());
      dispatch(getWidgetList());
      return true
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return false
    }
  };
}

export function getWidgetData(id: number) {
  return async () => {
    const {dispatch} = store;
    dispatch(slice.actions.startLoading());
    try {
      const {data} = await axios.get(`${apiBase}/get?id=${id}`);
      dispatch(slice.actions.setWidgetData({...data}));
      dispatch(slice.actions.onWidgetInListOperationSuccess());
      return data;
    } catch (error) {
      console.error(error);
      return dispatch(slice.actions.hasError(error));
    }
  }
}

export function updateWidget(widgetData: WidgetData) {
  return async () => {
    const {dispatch} = store;
    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`${apiBase}/update`, widgetData);
      dispatch(slice.actions.onWidgetInListOperationSuccess());
      return true;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return false;
    }
  }
}

export const {
  selectAll: selectAllWidgets,
  selectById: selectWidgetById,
  selectIds: selectWidgetsIds
} = widgetsAdapter.getSelectors((state: RootState) => state.widget.widgetDataList)

import {Link as RouterLink} from 'react-router-dom';
// material
import {styled} from '@material-ui/core/styles';
import {Avatar, Box, Card, Container, Link, Stack, Typography} from '@material-ui/core';
// routes
import {PATH_AUTH} from '../../routes/paths';
// hooks
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import {MHidden} from '../../components/@material-extend';
import {LoginForm} from '../../components/authentication/login';
import useLocales from "../../hooks/useLocales";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React from "react";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({theme}) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const {translate} = useLocales();

  return (
    <RootStyle title={translate('auth.login.caption')}>
      <AuthLayout>
        {translate('auth.login.text.noAccount')}&nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
          {translate('auth.login.text.registerLinkText')}
        </Link>
      </AuthLayout>

      <Container maxWidth="sm">
        <ContentStyle>
          <Box sx={{mb: 3, flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
            <Avatar>
              <LockOutlinedIcon/>
            </Avatar>
          </Box>
          <Stack direction="row" alignItems="center" sx={{mb: 5}}>
            <Box sx={{flexGrow: 1, flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
              <Typography variant="h4" gutterBottom>
                {translate('auth.login.text.signInHeader')}
              </Typography>
            </Box>
          </Stack>

          <LoginForm/>

          <Typography variant="body2" align="center" sx={{color: 'text.secondary', mt: 3}}>
            <Link underline="always" color="text.primary" href="/auth/reset-password">
              {translate('auth.restorePassword.linkText')}
            </Link>
          </Typography>

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{mt: 3}}>
              {translate('auth.login.text.noAccount')}&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                {translate('auth.login.text.registerLinkText')}
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

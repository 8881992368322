import React, {useEffect, useRef, useState} from "react";
import {Button, Dialog, DialogContent, Typography} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {saveVideo} from "../../redux/slices/videos";

import { isBrowser, isSafari } from 'react-device-detect';

import useLocales from "../../hooks/useLocales";
import { formatDurationInSeconds } from "../../utils/formatTime";

type RecordVideoDialogProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  nextStage: () => void;
  setUploadingLoadingState: (value: boolean) => void;
  onRecorded: () => void;
  setRecordedVideoId: (id: any) => void;
  setUploadPercent: (value: number) => void;
};

declare global {
  interface Window {
    stream?: MediaStream;
    recordedBlob: Blob[];
    currentSize: number;
  }
};

const StyledDialog = styled(Dialog)({
  '& .MuiBackdrop-root': {
    background: 'rgb(255 255 255 / 85%)'
  },
  '& .MuiPaper-root': {
    boxShadow: '0 0 40px 6px rgb(69 71 72 / 26%), 0 24px 48px 0px rgb(145 158 171 / 24%)',
    background: 'linear-gradient(90deg, #6d728b, #323339)',
  }
});

const StyledContainer = styled('div')({
  paddingRight: '5vw',
  paddingLeft: '5vw',
  minHeight: '65vh',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexDirection: 'column'
});

const StyledButtonContainer = styled('div')({
  position: 'fixed',
  zIndex: 1301,
  bottom: '4%',
  left: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const VideoRecordContainer = styled('div')({
  display: 'flex',
  backgroundColor: '#000',
  position: 'relative',
  paddingBottom: '75%',
  width: '100%'
});

const StyledVideo = styled('video')({
  width: '100%',
  position: 'absolute',
  height: '100%',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  objectFit: 'contain',
  objectPosition: 'top'
});

const StyledRecordButtonContainer = styled('div')({
  position: 'absolute',
  bottom: '7%',
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center'
});

const StyledRecordButton = styled('div')({
  height: 50,
  width: 50,
  backgroundColor: 'transparent',
  borderRadius: '50%',
  borderColor: '#fff',
  borderWidth: 2,
  borderStyle: 'solid',
  position: 'relative',
  transition: '0.3s cubic-bezier(.47, 1.64, .41, .8)',
  display: 'flex',
  '&:hover': {
    borderColor: '#f00',
    boxShadow: `0px 0px 0px 6px rgb(255 0 0 / 22%)`,
    transform: 'scale(1.2)'
  },
  '&:after': {
    width: '70%',
    height: '70%',
    content: '""',
    borderRadius: '50%',
    backgroundColor: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute'
  },
  '&:hover:after': {
    backgroundColor: '#f00',
  }
});

const StyledStopButton = styled('div')({
  height: 50,
  width: 50,
  backgroundColor: 'transparent',
  borderRadius: '50%',
  borderColor: '#fff',
  borderWidth: 2,
  borderStyle: 'solid',
  position: 'relative',
  transition: '0.3s cubic-bezier(.47, 1.64, .41, .8)',
  display: 'flex',
  '&:hover': {
    borderColor: '#f00',
    boxShadow: `0px 0px 0px 6px rgb(255 0 0 / 22%)`,
    transform: 'scale(1.2)'
  },
  '&:after': {
    width: '50%',
    height: '50%',
    content: '""',
    backgroundColor: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute'
  },
  '&:hover:after': {
    backgroundColor: '#f00',
  }
});

const RecordVideoDialog = ({
                             open,
                             setOpen,
                             nextStage,
                             setUploadingLoadingState,
                             onRecorded,
  setRecordedVideoId,
  setUploadPercent
                           }: RecordVideoDialogProps) => {
  const dispatch = useDispatch();

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [recording, setRecording] = useState(false);
  const [cameraOn, setCameraOn] = useState(false);
  const [camBlocked, setCamBlocked] = useState(false);
  const [recordingSuccess, setRecordingSuccess] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { translate } = useLocales();

  const [recordingSeconds, setRecordingSeconds] = useState(0);
  const maxSeconds = 300;
  const isMac = isBrowser && isSafari;

  useEffect(() => {
    if (open) {
      onAccessClick();
    }
  }, [open]);

  const onClose = () => {
    setCameraOn(false);
    setRecordingSuccess(false);
    setCamBlocked(false);
    if (recording) {
      mediaRecorder?.stop();
      setMediaRecorder(undefined);
      setRecording(false);

      window.recordedBlob = [];
      window.currentSize = 0;
    }
    if (window.stream) {
      window.stream.getTracks().forEach(track => track.stop());
      window.stream = undefined;
    }
    setOpen(false);
  };

  const setupStream = () => {
    const player = videoRef?.current;
    if (player && window.stream) {
      player.srcObject = window.stream;
    }
  };

  const initCamera = async () => {
    const constraints = {audio: true, video: true};
    try {
      window.stream = await navigator.mediaDevices.getUserMedia(constraints);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const onAccessClick = async () => {
    const result = await initCamera();
    if (result) {
      setCameraOn(true);
      setCamBlocked(false);
      setupStream();
    } else {
      setCameraOn(false);
      setCamBlocked(true);
    }
  };

  const getMimeType = () => {
    const possibleTypes = [
      'video/webm;codecs=vp9,opus',
      'video/webm;codecs=vp8,opus',
      'video/webm;codecs=h264,opus',
      'video/mp4;codecs=h264,aac',
    ];

    const safariType = 'video/mp4';

    if (MediaRecorder.isTypeSupported) {
      const supportedTypes = possibleTypes.filter(mimeType => {
        return MediaRecorder.isTypeSupported(mimeType);
      });
      if (supportedTypes.length) {
        return supportedTypes[0];
      }
      return undefined;
    }

    return safariType;
  }

  const startRecording = () => {
    const mimeType = getMimeType();
    if (mimeType || !isMac) {
      setRecording(true);
      window.recordedBlob = [];
      window.currentSize = 0;
      const recorder = new MediaRecorder(window.stream!, { mimeType });
      recorder.onstop = event => {
        const player = videoRef?.current;
        setRecording(false);

        if (player) {
          const buffer = new Blob(window.recordedBlob, {type: 'video/webm;codecs=h264,opus'});
          player.srcObject = null;
          player.src = URL.createObjectURL(buffer);
          player.autoplay = false;
          player.muted = false;
          player.controls = true;
        }
      };
      recorder.onerror = handleRecordingError;
      recorder.ondataavailable = handleDataAvailable;
      recorder.start(1000);
      setMediaRecorder(recorder);
    } else {
      console.log('Your device doesn\'t support required media types or your browser Safari-desktop');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setMediaRecorder(undefined);
    }
    setRecording(false);
    setRecordingSuccess(true);
    setRecordingSeconds(0);
    if (window.stream) {
      window.stream.getTracks().forEach(track => track.stop());
      window.stream = undefined;
    }
  };

  const handleRecordingError = (event: MediaRecorderErrorEvent) => {
    console.error(event.error);
    window.recordedBlob = [];
    window.currentSize = 0;
    window.stream = undefined;
  };

  const handleDataAvailable = (event: BlobEvent) => {
    const blobHasData = event.data && event.data.size > 0;
    setRecordingSeconds(prevState => {
      const newValue = ++prevState;
      if (newValue >= maxSeconds) {
        stopRecording();
        return 0;
      }
      return newValue;
    });
    if (blobHasData) {
      const newSize = window.currentSize + event.data.size;
      if (newSize < 500 * 1024 * 1024 * 0.9) { // ~500mb with a gap
        window.recordedBlob.push(event.data);
        window.currentSize = newSize;
      } else {
        stopRecording();
      }
    }
  };

  const updateProgress = (newValue: number) => {
    setUploadPercent(newValue);
  }

  const uploadVideo = async () => {
    const file = new File(window.recordedBlob, 'recordedVideo');
    onRecorded();
    setUploadingLoadingState(true);
    onClose();
    nextStage();

    const result = await dispatch(saveVideo(file, 'recordedVideo', updateProgress));
    if (result) {
      setUploadingLoadingState(false);
      setRecordedVideoId(result);
    }
  };

  const tryAgain = async () => {
    const player = videoRef?.current;
    if (player) {
      player.removeAttribute('src');
      player.autoplay = true;
      player.muted = true;
      player.controls = false;
    }

    window.recordedBlob = [];
    window.currentSize = 0;
    setRecordingSuccess(false);
    await onAccessClick();
  }

  return(
      <>
        <StyledDialog
            open={open}
            onClose={onClose}
            fullWidth
        >
          <DialogContent sx={{
            padding: 0,
            display: 'flex',
            width: '100%'
          }}>
            {(cameraOn && !isMac) &&
            <VideoRecordContainer>
              <StyledVideo
                  ref={videoRef}
                  playsInline={true}
                  muted={true}
                  autoPlay={true}
              />
              <StyledRecordButtonContainer>
                {!recording && !recordingSuccess && <StyledRecordButton onClick={startRecording}/>}
                {recording && <StyledStopButton onClick={stopRecording}/>}
              </StyledRecordButtonContainer>
              {recording &&
              <Typography
                  sx={{
                    position: 'absolute',
                    textAlign: 'center',
                    color: '#fff',
                    width: '100%',
                    top: '1%'
                  }}
              >
                {`${formatDurationInSeconds(recordingSeconds)} / ${formatDurationInSeconds(maxSeconds)}`}
              </Typography>
              }
            </VideoRecordContainer>
            }
            {(camBlocked || isMac) &&
            <StyledContainer>
              <Typography
                  sx={{
                    color: '#fff',
                    fontWeight: 500,
                    fontSize: '1.5rem',
                    textAlign: 'center'
                  }}
              >
                {isMac ? translate('recordVideoDialog.errorMacSafariText') : translate('recordVideoDialog.errorCamRequireText')}
              </Typography>
            </StyledContainer>
            }
          </DialogContent>
        </StyledDialog>
        {recordingSuccess &&
        <StyledButtonContainer>
          <Button
              variant="outlined"
              size="large"
              onClick={tryAgain}
              sx={{
                borderRadius: 50,
                marginRight: '1vw'
              }}
          >
            {translate('recordVideoDialog.tryAgainButton')}
          </Button>
          <Button
              variant="contained"
              size="large"
              onClick={uploadVideo}
              sx={{
                borderRadius: 50,
                width: 180
              }}
          >
            {translate('recordVideoDialog.saveButton')}
          </Button>
        </StyledButtonContainer>
        }
      </>
  );
};

export default RecordVideoDialog;

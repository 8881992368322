import * as React from 'react';
import {Menu, MenuItem} from '@material-ui/core';
import useLocales from "../../hooks/useLocales";
import Label from "../Label";
import baselineExpandMore from "@iconify/icons-ic/baseline-expand-more";
import {Icon} from "@iconify/react";
import {makeStyles} from "@material-ui/styles";
import {changeEnableStatus} from "../../redux/slices/campaigns";
import {dispatch} from "../../redux/store";
import {useSnackbar} from "notistack5";

type StatusProps = {
  enabled: boolean;
  entityId: number;
  enabledText: string;
  disabledText: string;
  enableStateChangedText: string;
  changeEntityStatus: (entityId: number, enabled: boolean) => Promise<boolean>;
};

export default function Status({enabled, entityId, enabledText, disabledText, enableStateChangedText, changeEntityStatus}: StatusProps) {
  const {translate} = useLocales();
  const {enqueueSnackbar} = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const changeStatus = async (newEnabled: boolean) => {
    handleClose(null);
    if (newEnabled !== enabled) {
      await changeEntityStatus(entityId, newEnabled)
    }
  };

  const handleClose = (newEnabled: any) => {
    setAnchorEl(null);
  };

  const useStyles = makeStyles({
    statusContainer: {
      "&:hover": {
        filter: 'brightness(120%)',
      },
    },
    changeIcon: {
      marginLeft: 3
    }
  });

  const styles = useStyles()

  return (
    <>
      <div onClick={handleClick} className={styles.statusContainer}>
        <Label
          variant={'ghost'}
          color={enabled ? 'success' : 'warning'}
          sx={{cursor: 'pointer'}}
        >
          {enabled ? enabledText : disabledText}
          <Icon className={styles.changeIcon} icon={baselineExpandMore}/>
        </Label>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => changeStatus(true)}>{enabledText}</MenuItem>
        <MenuItem onClick={() => changeStatus(false)}>{disabledText}</MenuItem>
      </Menu>
    </>
  );
}

// routes
import {PATH_DASHBOARD} from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{width: '100%', height: '100%'}}/>
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_chart'),
  video: getIcon('ic_video'),
  widget: getIcon('ic_widget'),
  testimonialsCreator: getIcon('ic_create_testimonials'),
  leaveReviewForVt: getIcon('ic_checklist'),
  campaignList: getIcon('ic_list'),
  newCampaign: getIcon('ic_post_add'),
  testimonials: getIcon('ic_ondemand_video'),
  referral: getIcon('ic_referral'),
  settings: getIcon('ic_settings')
};

export const sidebarConfig = [
  {
    subheader: '',//'mainMenu.sectionName',
    items: [
      {title: 'mainMenu.items.dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard},
      {title: 'mainMenu.items.createTestimonials', path: PATH_DASHBOARD.general.testimonialsCreator, icon: ICONS.testimonialsCreator},
      {title: 'mainMenu.items.campaignList', path: PATH_DASHBOARD.general.campaignList, icon: ICONS.campaignList},
      {title: 'mainMenu.items.createCampaign', path: PATH_DASHBOARD.general.createCampaign, icon: ICONS.newCampaign},

      {
        title: 'mainMenu.items.testimonials.root',
        path: PATH_DASHBOARD.general.reviewsRoot,
        icon: ICONS.testimonials,
        children: [
          {title: 'mainMenu.items.testimonials.all', path: PATH_DASHBOARD.general.allReviews},
          {title: 'mainMenu.items.testimonials.liked', path: PATH_DASHBOARD.general.likedReviews},
          {title: 'mainMenu.items.testimonials.deleted', path: PATH_DASHBOARD.general.deletedReviews},
        ],
      },

      {title: 'mainMenu.items.myVideos', path: PATH_DASHBOARD.general.myVideos, icon: ICONS.video},
      {title: 'mainMenu.items.widgetList', path: PATH_DASHBOARD.general.widgetList, icon: ICONS.widget}
    ],
  }
];

export const referralSidebarSectionConfig = [
  {
    subheader: 'referral.menu.sectionTitle',
    items: [
      {title: 'referral.menu.dashboard', path: PATH_DASHBOARD.general.referral, icon: ICONS.referral},
    ],
  }
]

export const adminSidebarSectionConfig = [
  {
    subheader: 'ADMIN',
    items: [
      {title: 'Tools', path: PATH_DASHBOARD.general.admin, icon: ICONS.settings},
    ],
  }
]


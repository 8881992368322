import React from 'react';

import { styled } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';


const OverlayButton = styled(Button)(({ theme }) => ({
    padding: theme.spacing(1, 3),
    borderRadius: '20px',
    color: theme.palette.primary["dark"],
    borderColor: theme.palette.primary["dark"],
    '&:hover': {
        backgroundColor: theme.palette.primary["dark"],
        color: '#fff',
    }
}));

export default OverlayButton;

import {Comparer, createSlice} from '@reduxjs/toolkit';
import {dispatch, RootState, store} from '../store';
// utils
import axios from '../../utils/axios';
import compareDesc from 'date-fns/compareDesc';
import {toDate} from '../../utils/formatTime';
import {CampaignData, CampaignInListData, CampaignState} from "../../@types/campaign";
import {createCampaignAdapter} from "../utils";
// ----------------------------------------------------------------------

const apiBase = "/api/v2/campaigns"

const byCreateDateComparator: Comparer<CampaignInListData> = (a, b) => compareDesc(a.createdAt, b.createdAt);
const campaignAdapter = createCampaignAdapter(byCreateDateComparator);

const initialState: CampaignState = {
  isLoading: false,
  error: false,
  campaignDataList: campaignAdapter.getInitialState(),
  campaignData: null,
  sortBy: null,
  deletingCampaign: null,
  editingCampaign: null
};

const slice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCampaignListSuccess(state, action) {
      state.isLoading = false;
      campaignAdapter.setAll(state.campaignDataList, action.payload);
    },

    getCampaignSuccess(state, action) {
      state.isLoading = false;
      state.campaignData = action.payload;
    },

    onCampaignInListOperationSuccess(state) {
        state.isLoading = false;
    },

    updateCampaign(state, action) {
      campaignAdapter.updateOne(state.campaignDataList, action.payload);
    },

    setCampaignDelete(state, action) {
      state.deletingCampaign = action.payload;
    },

    setCampaignEdit(state, action) {
      state.editingCampaign = action.payload;
    },

    deleteCampaignFromState(state, action) {
      state.deletingCampaign = null;
      campaignAdapter.removeOne(state.campaignDataList, action.payload);
    },

    sortByCampaign(state, action) {
      state.sortBy = action.payload;
    },
    setCampaignData(state, {payload}) {
      state.campaignData = payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByCampaign,
  setCampaignDelete
} = slice.actions;

export function getCampaignList() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: CampaignInListData[] } = await axios.get(apiBase);
      dispatch(slice.actions.getCampaignListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteCampaign(id: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(apiBase + '/delete/' + id);
      dispatch(slice.actions.onCampaignInListOperationSuccess());
      dispatch(slice.actions.deleteCampaignFromState(id));
      dispatch(getCampaignList());
      return true;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function cloneCampaign(id: number) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(apiBase + '/clone/' + id);
      dispatch(slice.actions.onCampaignInListOperationSuccess());
      dispatch(getCampaignList());
      return true;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function changeEnableStatus(id: number, enabled: boolean) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const patch = {
        "id": id,
        "enabled": enabled
      }
      await axios.post(apiBase + '/update-raw', patch);
      dispatch(slice.actions.onCampaignInListOperationSuccess());
      dispatch(getCampaignList());
      return true
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return false
    }
  };
}

export function getCampaignData (id: string) {
  return async () => {
    const {dispatch} = store;
    dispatch(slice.actions.startLoading());
    try {
      const {data} = await axios.get(`${apiBase}/${id}`);
      const collectedData = new Map<string, string>();
      data.collectedData.forEach((item: string) => {
        const arr = item.split(';;');
        collectedData.set(arr[0], arr[1]);
      });
      dispatch(slice.actions.setCampaignData({...data, collectedData: Object.fromEntries(collectedData)}));
      dispatch(slice.actions.onCampaignInListOperationSuccess());
      return data;
    } catch (error) {
      console.error(error);
      return dispatch(slice.actions.hasError(error));
    }
  }
}

export const {
  selectAll: selectAllCampaigns,
  selectById: selectCampaignById,
  selectIds: selectCampaignIds
} = campaignAdapter.getSelectors((state: RootState) => state.campaign.campaignDataList)

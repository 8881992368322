
export function reviewLink(id: string, customDomain?: string) {
  let apiBase;
  if (customDomain) {
    apiBase = customDomain;
  } else {
    apiBase = process.env.REACT_APP_REVIEW_LINK_BASE;
  }
  return apiBase + "/" + id;
}

export function wallLink(id: string) {
  if (!id) {
    return "";
  }
  const apiBase = process.env.REACT_APP_WALL_LINK_BASE;
  return apiBase + "/" + id;
}

export const handleVideoDownload = (videoUrl: string, reviewName: string) => {
  if (!videoUrl) {
    throw new Error("Resource URL not provided");
  }

  // use current host to prevent cors block. later nginx will rewrite the hostname to cdn
  const host = window.location.protocol + "//" + window.location.host;
  const url = new URL(videoUrl);
  const a = document.createElement('a');
  a.href = (host + url.pathname + url.search);
  a.target = '_blank';
  a.download = reviewName + ".mp4"
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

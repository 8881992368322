import React from 'react';

import {useField} from 'formik';
import {CustomOption} from "../../../components/forms/CustomRadioOptions";
import {MenuItem, Select, SelectProps} from "@mui/material";

type CampaignSelectProps = {
  fieldName: string;
  options: CustomOption[];
  selectProps?: SelectProps;
};

const CampaignSelect = ({fieldName, options, selectProps}: CampaignSelectProps) => {
  const [field] = useField(fieldName);

  return (<Select
    {...selectProps} aria-label={fieldName}
    {...field}
  >
    {options.map(option => (
      <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
    ))}
  </Select>);
};

export default CampaignSelect;

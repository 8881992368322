import {createSlice} from '@reduxjs/toolkit';
import {VideoPlayerManagerState} from '../../@types/videoPlayerManagerTypes';

const initialState: VideoPlayerManagerState = {
  playingVideoId: null
};

const slice = createSlice({
  name: 'videoPlayerManager',
  initialState,
  reducers: {
    setPlayingVideoId(state, {payload}) {
      state.playingVideoId = payload;
    },
    clearPlayingVideoId(state) {
      state.playingVideoId = null;
    }
  }
});


export default slice.reducer;

export const {
  setPlayingVideoId,
  clearPlayingVideoId
} = slice.actions;

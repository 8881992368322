import {Comparer, createEntityAdapter} from "@reduxjs/toolkit";
import {VideoData} from "../@types/video";
import {CampaignInListData} from "../@types/campaign";
import {WidgetInListData} from "../@types/widget";
import {ReviewData} from "../@types/review";

export const createVideoAdapter = (sortComparer: Comparer<VideoData> | undefined) => {
    return createEntityAdapter<VideoData>({
        selectId: video => video.id,
        sortComparer: sortComparer
    });
};

export const createCampaignAdapter = (sortComparer: Comparer<CampaignInListData> | undefined) => {
    return createEntityAdapter<CampaignInListData>({
        selectId: campaign => {
            return campaign.id
        },
        sortComparer: sortComparer
    });
};

export const createWidgetsAdapter = (sortComparer: Comparer<WidgetInListData> | undefined) => {
    return createEntityAdapter<WidgetInListData>({
        selectId: widget => {
            return widget.id
        },
        sortComparer: sortComparer
    });
};

export const createReviewAdapter = (sortComparer: Comparer<ReviewData> | undefined) => {
    return createEntityAdapter<ReviewData>({
        selectId: review => review.id,
        sortComparer: sortComparer
    });
};

import * as yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';

import {Form, FormikProvider, useFormik} from 'formik';

import {styled} from "@mui/material/styles";
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';

import {useSnackbar} from 'notistack5';

import StepCard from '../../components/forms/StepCard';

import useLocales from "../../hooks/useLocales";
import {updateCampaign,} from '../../redux/slices/campaignCreate';
import {CampaignEditFormData} from '../../@types/campaign';

import {Icon} from '@iconify/react';
import React, {useEffect} from "react";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Page from "../../components/Page";
import {Container} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import CustomField from "../../components/forms/CustomField";
import VideoLengthSlider from "./components/VideoLengthSlider";
import CampaignCheckboxesArray, {defaultFieldsValues} from "./components/CampaignCheckboxesArray";
import Grid from "@mui/material/Grid";
import {getCampaignData} from "../../redux/slices/campaigns";
import {useNavigate, useParams} from "react-router-dom";
import {RootState} from "../../redux/store";
import CampaignFieldsArray from "./components/CampaignFieldsArray";
import plusOutline from "@iconify/icons-eva/plus-outline";
import Box from "@mui/material/Box";
import OverlayButton from "../video/components/OverlayButton";
import {getProfile} from "../../redux/slices/user";
import CampaignSelect from "./components/CampaginSelect";
import ColorPickerField from "../../components/forms/ColorPickerField";

const editCampaignSchema = yup.object().shape({
    externalUrl: yup.string().required('campaign.content.link.errors.required'),
    externalUrlText: yup.string().required('campaign.content.linkText.errors.required'),
    introText: yup.string().required('campaign.content.title.errors.required'),
    // description: yup.string().required('campaign.content.description.errors.required'),
    finishText: yup.string().required('campaign.appearance.finishText.errors.required'),
    name: yup.string().required('campaign.appearance.name.errors.required'),
    rawCollectedData: yup.array().min(1, 'campaign.testimonialsCollection.min')
});

export type EditCampaignFieldsProps = {
    lang: string;
    externalUrl: string;
    externalUrlText: string;
    introText: string;
    description: string;
    questionExampleHeader: string;
    questionExamples: string[];
    promoText: string;
    name: string;
    finishText: string;
    color: string;
    maxVideoLengthSeconds: number;
    rawCollectedData?: string[];
    collectedData?: Map<string, string>;
    requiredCollectedData?: string[];
};

const StyledButton = styled(OverlayButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    border: '1px solid transparent',
    '&:hover': {
        border: '1px solid #fff',
        backgroundColor: theme.palette.primary.main,
        color: '#fff'
    }
}));

const StyledConfirmButton = styled(StyledButton)(({ theme }) => ({
    color: '#fff',
    borderColor: 'transparent',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: '#fff',
        border: '1px solid ' + theme.palette.primary.main,
        color: theme.palette.primary.main,
    }
}));

export const StyledStepContent = styled(StepContent)(({ theme }) => ({
    color: theme.palette.grey['500']
}));

const CampaignEditForm = () => {
    const { themeStretch } = useSettings();
    const { translate } = useLocales();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { campaignId } = useParams();
    const campaignData = useSelector((state: RootState) => state.campaign.campaignData);
    const checkBoxUserFields = useSelector((state: RootState) => {
        if (state.campaign.campaignData?.collectedData) {
            return Object.keys(state.campaign.campaignData?.collectedData)
                .filter((key: string) => !defaultFieldsValues.includes(key))
                .map((key: string) => {
                    // @ts-ignore
                    return state.campaign.campaignData?.collectedData[key];
                });
        } else {
            return [];
        }
    });

    const { userProfile } = useSelector(
      (state: RootState) => state.user
    );

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    const selectedFields = useSelector((state: RootState) => {
        if (state.campaign.campaignData?.collectedData) {
            return Object.keys(state.campaign.campaignData?.collectedData)
                .map((key: string) => {
                    if (!defaultFieldsValues.includes(key)) {
                        // @ts-ignore
                        return state.campaign.campaignData?.collectedData[key];
                    } else {
                        return key;
                    }
                });
        } else {
            return [];
        }
    })


    useEffect(() => {
        dispatch(getCampaignData(campaignId));
    }, [dispatch]);

    const mixpanel = window.mixpanel;
    useEffect(() => {
        if (userProfile) {
            mixpanel?.track("campaign edit start", {
                uid: userProfile.id,
            });
        }
    }, [userProfile != null]);

    const formik = useFormik<EditCampaignFieldsProps>({
        enableReinitialize: true,
        initialValues: {
            lang: campaignData?.lang ?? '',
            externalUrl: campaignData?.externalUrl ?? '',
            externalUrlText: campaignData?.externalUrlText ?? '',
            introText: campaignData?.introText ?? '',
            description: campaignData?.description ?? '',
            questionExampleHeader: campaignData?.questionExampleHeader ?? '',
            questionExamples: campaignData?.questionExamples ?? [''],
            promoText: campaignData?.promoText ?? '',
            name: campaignData?.name ?? '',
            finishText: campaignData?.finishText ?? '',
            color: campaignData?.color ?? '#5069F6',
            maxVideoLengthSeconds: campaignData?.maxVideoLengthSeconds ?? 30,
            rawCollectedData: selectedFields ?? [],
            requiredCollectedData: campaignData?.requiredCollectedData ?? []
        },
        validationSchema: editCampaignSchema,
        onSubmit: async (values, {setErrors, setSubmitting}) => {
            try {
                const translationChecksPrefix = 'campaign.testimonialsCollection.fields';
                let collectedData = new Map<string, string>();
                let userFieldsCount = 0;

                values.rawCollectedData?.forEach(el => {
                    const translationKey = `${translationChecksPrefix}.${el}`;
                    const translatedEl = translate(translationKey);
                    if (translatedEl === translationKey) { //new user field
                        collectedData.set(`${userFieldsCount++}`, el);
                    } else {
                        collectedData.set(el, translatedEl);
                    }
                });

                const performedData = {
                    ...values,
                    collectedData: Object.fromEntries(collectedData)
                };

                const formData: CampaignEditFormData = {
                    id: campaignId,
                    lang: performedData.lang,
                    externalUrl: performedData.externalUrl,
                    externalUrlText: performedData.externalUrlText,
                    introText: performedData.introText,
                    finishText: performedData.finishText,
                    name: performedData.name,
                    description: performedData.description,
                    questionExampleHeader: performedData.questionExampleHeader,
                    questionExamples: [...performedData.questionExamples],
                    promoText: performedData.promoText,
                    color: performedData.color,
                    maxVideoLengthSeconds: performedData.maxVideoLengthSeconds,
                    collectedData: { ...performedData.collectedData },
                    requiredCollectedData: performedData.requiredCollectedData,
                };

                setSubmitting(true);
                await dispatch(updateCampaign(formData));
                setSubmitting(false);
                enqueueSnackbar(translate('campaign.messages.editSuccess'), {variant: 'success'});
                navigate('/campaigns');
            } catch (error) {
                console.error(error);
                setErrors(error.message);
            }
        }
    });

    if (!userProfile) {
        return null;
    }

    const { handleSubmit } = formik;

    let langOptions = [
        {
            value: 'es',
            label: translate(`campaign.content.lang.esOption`)
        },
        {
            value: 'de',
            label: translate(`campaign.content.lang.deOption`)
        },
        {
            value: 'fr',
            label: translate(`campaign.content.lang.frOption`)
        },
        {
            value: 'ru',
            label: translate(`campaign.content.lang.ruOption`)
        },
        {
            value: 'en',
            label: translate(`campaign.content.lang.enOption`)
        },
        {
            value: 'ro',
            label: translate(`campaign.content.lang.roOption`)
        },
        {
            value: 'zh',
            label: translate(`campaign.content.lang.zhOption`)
        },
        {
            value: 'cs',
            label: translate(`campaign.content.lang.csOption`)
        },
        {
            value: 'pt-br',
            label: translate(`campaign.content.lang.ptBrOption`)
        }
    ];

    if (userProfile.lang && userProfile.lang.startsWith("ru")) {
        langOptions = langOptions.filter(opt => opt.value !== 'ru')
        langOptions.splice(0, 0, {
            value: 'ru',
            label: translate(`campaign.content.lang.ruOption`)
        });
    } else {
        langOptions = langOptions.filter(opt => opt.value !== 'en')
        langOptions.splice(0, 0, {
            value: 'en',
            label: translate(`campaign.content.lang.enOption`)
        });
    }

    return (
        <Page title={translate('campaign.breadcrumbs.editCampaign')}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" onSubmit={handleSubmit}>
                        <StepCard title={translate('campaign.content.header')}>
                            <Stepper orientation="vertical">
                                <Step key='campaign.content.lang' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate('campaign.content.lang.header')}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
                                            {translate(`campaign.content.lang.subtitle`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CampaignSelect
                                          fieldName="lang"
                                          options={langOptions}
                                        />
                                    </StyledStepContent>
                                </Step>
                                <Step key='campaign.content.linkText' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`campaign.content.linkText.header`)}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
                                            {translate(`campaign.content.linkText.subtitle`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CustomField
                                          inputId="externalUrlText"
                                          mergeInputProps={{
                                              placeholder: translate('campaign.content.linkText.placeholder')
                                          }}
                                        />
                                    </StyledStepContent>
                                </Step>
                                <Step key='campaign.content.link' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`campaign.content.link.header`)}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
                                            {translate(`campaign.content.link.subtitle`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CustomField
                                            inputId="externalUrl"
                                            mergeInputProps={{
                                                placeholder: translate('campaign.content.link.placeholder')
                                            }}
                                        />
                                    </StyledStepContent>
                                </Step>
                                <Step key='campaign.content.title' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`campaign.content.title.header`)}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
                                            {translate(`campaign.content.title.subtitle`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CustomField
                                            inputId="introText"
                                            multiline={true}
                                            mergeInputProps={{
                                                placeholder: translate('campaign.content.title.placeholder')
                                            }}
                                        />
                                    </StyledStepContent>
                                </Step>
                                {/*<Step key='campaign.content.description' active={true} expanded={true}>*/}
                                {/*    <StepLabel>*/}
                                {/*        <Typography variant="subtitle1" sx={{p: 1}}>*/}
                                {/*            {translate(`campaign.content.description.header`)}*/}
                                {/*        </Typography>*/}
                                {/*        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>{translate(`campaign.content.description.subtitle`)}</Typography>*/}
                                {/*    </StepLabel>*/}
                                {/*    <StyledStepContent>*/}
                                {/*        <CustomField*/}
                                {/*            inputId="description"*/}
                                {/*            multiline={true}*/}
                                {/*            mergeInputProps={{*/}
                                {/*                placeholder: translate('campaign.content.description.placeholder')*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    </StyledStepContent>*/}
                                {/*</Step>*/}
                                <Step key='campaign.content.questions' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`campaign.content.questions.header`)}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
                                            {translate(`campaign.content.questions.subtitleStart`)}
                                            &nbsp;
                                            <a href={translate(`campaign.content.questions.subtitleLinkUrl`)} target="_blan">
                                                {translate(`campaign.content.questions.subtitleLinkCaption`)}
                                            </a>
                                            &nbsp;
                                            {translate(`campaign.content.questions.subtitleEnd`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CampaignFieldsArray
                                            name="questionExamples"
                                            defaultValue=""
                                            renderAddButton={(onAdd) => (
                                                <Button onClick={onAdd} startIcon={<Icon icon={plusOutline} />}>
                                                    {translate('campaign.content.questions.addButton')}
                                                </Button>
                                            )}
                                        />
                                    </StyledStepContent>
                                </Step>
                                <Step key='campaign.content.questionHeader' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`campaign.content.questionHeader.header`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CustomField
                                          inputId="questionExampleHeader"
                                          multiline={false}
                                          mergeInputProps={{
                                              placeholder: translate('campaign.content.questionHeader.placeholder')
                                          }}
                                        />
                                    </StyledStepContent>
                                </Step>
                                <Step key='campaign.content.motivation' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`campaign.content.motivation.header`)}
                                        </Typography>
                                        <Typography variant="body1" sx={{p: 0.5, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>{translate(`campaign.content.motivation.subtitle`)}</Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CustomField
                                          inputId="promoText"
                                          multiline={true}
                                        />
                                    </StyledStepContent>
                                </Step>
                            </Stepper>
                        </StepCard>
                        <StepCard title={translate('campaign.appearance.header')}>
                            <Stepper orientation="vertical">
                                <Step key={'campaign.appearance.color' + formik.initialValues.color} active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`campaign.appearance.color.header`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <ColorPickerField fieldName="color"/>
                                    </StyledStepContent>
                                </Step>
                                <Step key='campaign.appearance.videoLength' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`campaign.appearance.videoLength.header`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <VideoLengthSlider fieldName="maxVideoLengthSeconds" />
                                    </StyledStepContent>
                                </Step>
                                <Step key='campaign.appearance.finishText' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`campaign.appearance.finishText.header`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CustomField
                                            inputId="finishText"
                                            multiline={true}
                                            mergeInputProps={{
                                                placeholder: translate('campaign.appearance.finishText.placeholder')
                                            }}
                                        />
                                    </StyledStepContent>
                                </Step>
                                <Step key='campaign.appearance.name' active={true} expanded={true}>
                                    <StepLabel>
                                        <Typography variant="subtitle1" sx={{p: 1}}>
                                            {translate(`campaign.appearance.name.header`)}
                                        </Typography>
                                    </StepLabel>
                                    <StyledStepContent>
                                        <CustomField
                                            inputId="name"
                                            mergeInputProps={{
                                                placeholder: translate('campaign.appearance.name.placeholder')
                                            }}
                                        />
                                    </StyledStepContent>
                                </Step>
                            </Stepper>
                        </StepCard>
                        <StepCard title={translate('campaign.testimonialsCollection.header')}>
                            <Stepper orientation="vertical">
                                <Step key='campaign.testimonialsCollection.name' active={true} expanded={true}>
                                    <Grid container spacing={1}>
                                        <CampaignCheckboxesArray name="rawCollectedData" maxUserFields={5} userFields={checkBoxUserFields} />
                                    </Grid>
                                    <Typography variant="h5" sx={{py: 3}}>
                                        {translate('campaign.testimonialsCollection.requiredFieldSubHeader')}
                                    </Typography>
                                    <Grid container spacing={1}>
                                        <CampaignCheckboxesArray name="requiredCollectedData" withoutNewFields={true} staticField={'rawCollectedData'} />
                                    </Grid>
                                </Step>
                            </Stepper>
                        </StepCard>
                        <Box display="flex" sx={{ width: '100%', justifyContent: 'center' }}>
                            <StyledButton onClick={() => navigate('/campaigns')} sx={{ mr: 3 }}>
                                {translate('campaign.action.edit.cancel')}
                            </StyledButton>
                            <StyledConfirmButton type="submit">
                                {translate('campaign.action.edit.ok')}
                            </StyledConfirmButton>
                        </Box>
                    </Form>
                </FormikProvider>
            </Container>
        </Page>
    );
};

export default CampaignEditForm;

import {createSlice} from '@reduxjs/toolkit';
import {dispatch} from '../store';
import axios from '../../utils/axios';
import {StatisticsData} from "../../@types/statistics";

type StatisticsState = {
  isLoading: boolean;
  error: boolean;
  stats: null | StatisticsData;
};

const initialState: StatisticsState = {
  isLoading: false,
  error: false,
  stats: null,
};

const slice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.stats = action.payload;
    },
  }
});

export default slice.reducer;

export function getStats() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/users/getStats');
      dispatch(slice.actions.getStatisticsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


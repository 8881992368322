import React from 'react';

import { FieldArray, useField } from 'formik';

import Box from '@mui/material/Box';

import CustomField from '../../../components/forms/CustomField';

type CampaignFieldsArrayProps = {
    name: string;
    defaultValue: any;
    renderAddButton?(handler: React.MouseEventHandler): void;
    renderRemoveButton?(handler: React.MouseEventHandler): void;
    customOnFocusHandler?: () => void;
}

const CampaignFieldsArray = ({ name, defaultValue, renderAddButton, renderRemoveButton, customOnFocusHandler }: CampaignFieldsArrayProps) => {
    const [field] = useField(name);

    return (
        <FieldArray name={name}>
            {({ push, remove }) => (
                <>
                    {field.value.length > 0 && field.value.map((el: string, index: number) => {
                        const fieldAlias = `${name}[${index}]`;

                        return <Box key={fieldAlias} sx={{ mb: 2, width: '100%' }}>
                            <CustomField customOnFocusHandler={customOnFocusHandler} inputId={fieldAlias} arrayInputId={name} />
                        </Box>
                    })}
                    {renderAddButton && renderAddButton(() => push(defaultValue))}
                    {renderRemoveButton && renderRemoveButton(() => remove(defaultValue))}
                </>
            )}
        </FieldArray>
    );
};

export default CampaignFieldsArray;

import React from 'react';

import {useSelector} from 'react-redux';
import {getIsFieldSkipped} from '../../redux/slices/campaignCreate';
import {useField} from 'formik';

import TextField from '../common/TextField';

import {SxProps, Theme} from '@mui/material/styles';
import {InputBaseProps} from '@mui/material/InputBase';
import {RootState} from '../../redux/store';
import useLocales from '../../hooks/useLocales';

import useScrollToInvalidField from "../../hooks/useScrollToInvalidField";

type CampaignFieldProps = {
    inputId: string;
    arrayInputId?: string;
    customOnFocusHandler?: () => void;
    mergeInputProps?: InputBaseProps;
    multiline?: boolean;
};

const sxCampaignInput: SxProps<Theme> = {
    border: '1px solid #dedede',
    padding: '15px 30px',
    borderRadius: 2,

    '& .MuiInputBase-input': {
        position: 'relative'
    }
};

const CustomField = ({inputId, arrayInputId = '', mergeInputProps, customOnFocusHandler, multiline}: CampaignFieldProps) => {
    const [field, meta] = useField(inputId);
    const inputRef = useScrollToInvalidField(inputId);
    const {translate} = useLocales();
    const isFieldSkipped = useSelector((state: RootState) => getIsFieldSkipped(state, inputId));
    const isFieldSkippedInArray = useSelector((state: RootState) => getIsFieldSkipped(state, arrayInputId));
    const additionalInputProps: InputBaseProps = {};


    if (isFieldSkipped || isFieldSkippedInArray) {
        additionalInputProps.placeholder = translate('campaign.general.skippedPlaceholder');
    }

    const handleCustomFocus = () => {
        customOnFocusHandler && customOnFocusHandler();
    };

    return (<TextField sxInput={sxCampaignInput}
                       customOnFocusHandler={handleCustomFocus}
                       meta={meta}
                       formikFieldProps={field}
                       inputId={inputId}
                       multiline={multiline}
                       inputProps={{
                           fullWidth: true,
                           ...mergeInputProps,
                           ...additionalInputProps
                       }}
                       ref={inputRef}
    />);
};

export default CustomField;


import {alpha, styled} from '@material-ui/core/styles';
import {Card, CardContent, Stack, Typography} from '@material-ui/core';
// @types
import {VideoData} from '../../@types/video';
import {EntityId} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import {selectVideoById} from '../../redux/slices/videos';
import {formatNumDate, formatDurationInSeconds} from "../../utils/formatTime";
import React, {ClassAttributes, useState, VideoHTMLAttributes} from "react";

import noImagePlaceholder from '../../assets/no_image_placeholder.jpg';

// ----------------------------------------------------------------------

type CardProps = {
  isSelected?: boolean;
} & ClassAttributes<any> & VideoHTMLAttributes<any>

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<CardProps>(({isSelected}) => ({
  borderRadius: 0,
  maxHeight: '300px',
  paddingTop: '100%',
  cursor: 'pointer',
  position: 'relative',
  borderColor: 'transparent',
  borderWidth: 4,
  borderStyle: 'groove',
  ...(isSelected && {
    borderColor: '#4965c8',
  })
}));

const StyledPoster = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  objectPosition: 'top',
});

const CaptionStyle = styled(CardContent)(({ theme }) => ({
  position: 'absolute',
  bottom: '25px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '90%',
  maxHeight: 80,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  padding: '10px !important',
  backdropFilter: 'blur(3px)',
  WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
  justifyContent: 'space-between',
  color: '#fff',
  backgroundColor: alpha(theme.palette.grey[900], 0.60),
  borderRadius: theme.shape.borderRadius
}));

const NameBlock = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const DateBlock = styled(Stack)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
// ----------------------------------------------------------------------

type GalleryItemProps = {
  videoId: EntityId;
  openPreviewModal: () => void;
  selectVideo: (id: EntityId) => void;
  selectedVideoId: EntityId | undefined;
};

const VideoCardForSelect = ({ videoId, openPreviewModal, selectVideo, selectedVideoId }: GalleryItemProps) => {
  const { thumbnailImageUrl, lengthSeconds, videoFileName, createdAt } = useSelector((state: RootState) => selectVideoById(state, videoId)) || {} as VideoData;
  const dispatch = useDispatch();

  const isSelected = selectedVideoId === videoId;

  const handleClick = () => {
    selectVideo(videoId);
    openPreviewModal();
  }

  return (
    <StyledCard onClick={handleClick} isSelected={isSelected}>
      <StyledPoster
        src={thumbnailImageUrl ? thumbnailImageUrl : noImagePlaceholder}
      />
      <CaptionStyle>
        <NameBlock variant="body2" title={videoFileName}>
          {videoFileName}
        </NameBlock>
        <DateBlock direction="row" spacing={1}>
          <Typography variant="body2" sx={{ opacity: 0.72 }}>{formatNumDate(createdAt!)}</Typography>
          <Typography variant="caption">{formatDurationInSeconds(lengthSeconds)}</Typography>
        </DateBlock>
      </CaptionStyle>
    </StyledCard>
  );
};

export default VideoCardForSelect;

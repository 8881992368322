import {EntityState} from "@reduxjs/toolkit";

export const CREATE_WIDGET_STAGES = [
  'Intro',
  'CreateWidget',
  'Finish'
] as const;

export const CREATE_WIDGET_MODS = [
  'WithVideoUpload',
  'WithoutVideoUpload'
] as const;

export type WidgetCreateFormData = {
  name?: string;
  ctaText?: string;
  unmuteText?: string;
  containerType?: string;
  position?: string;
  ctaDelay?: number;
  ctaLink?: string;
  ctaColor?: string;
  highlightColor?: string;
  closeColor?: string;
  videoId?: number;
}

export type WidgetEditFormData = {
  id: number;
  name?: string;
  ctaText?: string;
  unmuteText?: string;
  containerType?: string;
  position?: string;
  ctaDelay?: number;
  ctaLink?: string;
  ctaColor?: string;
  highlightColor?: string;
  closeColor?: string;
}

export type WidgetInListData = {
  id: number;
  createdAt: number;
  name: string;
  enabled: boolean;
  containerType: string;
  publicId: string;
  ctaClickPercent: number;
  threeQuartileViewPercent: number;
};

export type WidgetData = {
  id: number;
  name?: string;
  ctaText?: string;
  unmuteText?: string;
  containerType?: string;
  position?: string;
  ctaDelay?: number;
  ctaLink?: string;
  ctaColor?: string;
  highlightColor?: string;
  closeColor?: string;
};

export type WidgetState = {
  isLoading: boolean;
  error: boolean;
  widgetDataList: EntityState<WidgetInListData>;
  widgetData: WidgetData | null;
  sortBy: string | null;
  deletingWidget: number | null;
  editingWidget: number | null;
};

export type WidgetCreateSlice = {
  createMode: typeof CREATE_WIDGET_MODS[number],
  createWidgetManager: {
    isLoading: boolean;
    error: string | null;
    data: any;
  },
  uploadVideoManager: {
    percent: number;
    isLoading: boolean;
    data: any;
  },
  videoId?: number;
  activeStage: {
    stageKey: typeof CREATE_WIDGET_STAGES[number];
    isErrorOnTransition: boolean;
    isLoading: boolean;
  },
  fields: WidgetCreateFormData;
};

import React, {useState, useRef, useEffect, forwardRef } from 'react';

import { SxProps, Theme } from "@mui/material";

import Input, {InputBaseProps} from '@mui/material/InputBase';
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';

import {FieldInputProps, FieldMetaProps} from "formik";
import useLocale from "../../hooks/useLocales";

type TextFieldProps = {
    formikFieldProps?: FieldInputProps<any>;
    customOnFocusHandler?: () => void;
    inputId: string;
    label?: string;
    sxInput?: SxProps<Theme>;
    sxLabel?: SxProps<Theme>;
    meta: FieldMetaProps<any>;
    inputProps: InputBaseProps;
    multiline?: boolean;
};

const TextField = forwardRef(({ meta, customOnFocusHandler, inputId, inputProps, formikFieldProps, label, sxInput = {}, sxLabel = {}, multiline }: TextFieldProps, ref) => {
    const { translate } = useLocale();
    const [value, setValue] = useState(formikFieldProps?.value ?? '');

    useEffect(() => {
        if (formikFieldProps && formikFieldProps.value) {
            setValue(formikFieldProps.value);
        }
    }, [formikFieldProps]);

    // @ts-ignore
    const timeout = useRef(null);

    const optimizedHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setValue(e.target.value);
        // @ts-ignore
        if (timeout.current) clearTimeout(timeout.current);
        // @ts-ignore
        timeout.current = setTimeout(() => {
            formikFieldProps?.onChange(e);
        }, 1);
    };

    const customizedHandleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        customOnFocusHandler && customOnFocusHandler();
    };

    const { touched, error } = meta;
    const isErrorDisplayed = touched && error;

    return (
        <FormControl style={{ width: 'inherit' }} error={touched && Boolean(error)} variant="standard">
            { label && <InputLabel shrink htmlFor={inputId} sx={sxLabel}>{label}</InputLabel>}
            <Input {...formikFieldProps}
                   {...inputProps}
                   inputRef={ref}
                   sx={sxInput}
                   id={inputId}
                   multiline={multiline}
                   error={touched && Boolean(error)}
                   value={value}
                   onFocus={customizedHandleFocus}
                   onChange={optimizedHandleChange}
            />
            {isErrorDisplayed && <FormHelperText>{translate(error as string)}</FormHelperText>}
        </FormControl>
    )
});

export default TextField;

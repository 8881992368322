export function widgetCode(publicId: string, embedded: boolean) {
  const apiBase = process.env.REACT_APP_WIDGET_LINK_BASE;
  if (embedded) {
    return `<!-- VideoTouch -->
<iframe src="${apiBase}/vs/embedded?wid=${publicId}"
 allow="autoplay *;" width="100%" height="100%" style="border: none; border-radius: 4px; max-height: 380px;max-width: 400px;">
</iframe>
<!-- /VideoTouch -->`
  } else {
    return `<!-- VideoTouch -->
<script src="${apiBase}/vs/load-widget?wid=${publicId}" async></script>
<!-- /VideoTouch -->`
  }
}

export function formatStats(value: number): string {
  return (Math.round(value * 100) / 100).toFixed(0)
}

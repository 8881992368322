import {ReactNode, useEffect} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {useSnackbar} from "notistack5";
import useLocales from "../hooks/useLocales";
import useAuth from "../hooks/useAuth";

type Props = {
  children?: ReactNode;
};

export default function DataErrorMonitor({children}: Props) {
  const hasUserError = useSelector((state: RootState) => state.user.error);
  const hasVideoError = useSelector((state: RootState) => state.video.error);
  const hasReviewError = useSelector((state: RootState) => state.review.error);
  const hasStatisticsError = useSelector((state: RootState) => state.statistics.error);
  const hasPaymentError = useSelector((state: RootState) => state.paymentDetails.error);
  const hasCampaignCreateError = useSelector((state: RootState) => state.campaignCreate.createCampaignManager.error);
  const hasCampaignError = useSelector((state: RootState) => state.campaign.error);
  const hasReferralError = useSelector((state: RootState) => state.referral.error);
  const hasWidgetError = useSelector((state: RootState) => state.widget.error);
  const {error} = useAuth();

  const {enqueueSnackbar} = useSnackbar();
  const {translate} = useLocales();
  const mixpanel = window.mixpanel;

  useEffect(() => {
    if (hasUserError || hasVideoError || hasReviewError || hasStatisticsError ||
      hasPaymentError || error || hasCampaignCreateError || hasCampaignError || hasReferralError || hasWidgetError) {
      enqueueSnackbar(translate('general.notification.apiError'),
        {variant: 'error', preventDuplicate: true});
      mixpanel?.track('api general error')
    }
  })

  return (
    <>
      {children}
    </>
  );
}

import {useEffect} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
import {alpha, styled} from '@material-ui/core/styles';
import {
  Avatar,
  Box,
  CardActionArea,
  Drawer,
  Link,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@material-ui/core';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import {MHidden} from '../../components/@material-extend';
import {adminSidebarSectionConfig, referralSidebarSectionConfig, sidebarConfig,} from './SidebarConfig';
import {RootState, useSelector} from "../../redux/store";
import useLocales from "../../hooks/useLocales";
import {MenuItem} from "@mui/material";
import outlineChecklistRtl from '@iconify/icons-ic/outline-checklist-rtl';
import {Icon} from "@iconify/react";
import {PATH_DASHBOARD} from "../../routes/paths";


const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({theme}) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
  transition: 'transform .2s',
  border: '1px solid transparent',
  '&:hover': {
    border: '1px solid',
    transform: 'scale(1.03)',
  }
}));

// ----------------------------------------------------------------------

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({onToggleCollapse, collapseClick}: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({isOpenSidebar, onCloseSidebar}: DashboardSidebarProps) {
  const {pathname} = useLocation();
  const {translate} = useLocales();

  const {isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave} =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const {userProfile} = useSelector(
    (state: RootState) => state.user
  );

  if (!userProfile) {
    return null;
  }

  const statusIconPath = "/static/icons/ic_premium.svg";
  let statusTextKey;
  let statusIconStyle = {}
  if (userProfile.subscriptionStatus == 'FREE') {
    statusTextKey = "accountStatus.free";
    statusIconStyle = {filter: 'grayscale(90%)'};
  } else {
    statusTextKey = "accountStatus.paid";
  }

  let sidebarCfg = sidebarConfig.slice();

  if (userProfile.features && !userProfile.features.includes("w")) {
    sidebarCfg[0].items = sidebarCfg[0].items.filter(item => item.path !== PATH_DASHBOARD.general.widgetList);
  }

  if (userProfile.roles && userProfile.roles.includes("REFERRAL")) {
    sidebarCfg = sidebarCfg.concat(referralSidebarSectionConfig);
  }

  if (userProfile.roles && userProfile.roles.includes("ADMIN")) {
    sidebarCfg = sidebarCfg.concat(adminSidebarSectionConfig);
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {height: '100%', display: 'flex', flexDirection: 'column'}
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box/>
          <Box component={RouterLink} to="/" sx={{display: 'inline-flex'}}>
            <Logo/>
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && (
              <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick}/>
            )}
          </MHidden>
        </Stack>

        {isCollapse ? (
          <Avatar
            style={statusIconStyle}
            src={statusIconPath}
            sx={{mx: 'auto', mb: 2}}
          />
        ) : (
          <Link underline="none" component={RouterLink} to="/profile?tab=billing">
            <AccountStyle>
              <Avatar src={statusIconPath} style={statusIconStyle}/>
              <Box sx={{ml: 2, margin: 'auto'}}>
                <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                  {translate(statusTextKey)}
                </Typography>
                {/*<Typography variant="body2" sx={{ color: 'text.secondary' }}>*/}
                {/*  role*/}
                {/*</Typography>*/}
              </Box>
            </AccountStyle>
          </Link>
        )}
      </Stack>

      <NavSection navConfig={sidebarCfg} isShow={!isCollapse}/>

      {!isCollapse && <Stack
          spacing={3}
          sx={{
            height: '100%',
            position: 'relative',
            px: 2.5,
            pt: 10,
            pb: 2,
          }}
      >

        {/*TODO menu bottom widget */}

        {/*<Link underline="none" component={RouterLink} to="#">*/}
        {/*  <AccountStyle>*/}
        {/*    <Box style={{width: '100%'}}>*/}
        {/*      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>*/}
        {/*        {translate('accountStatus.stats.impressionAvailable')}*/}
        {/*      </Typography>*/}
        {/*        <LinearProgress variant="determinate" value={66} sx={{mt:1, mb:1}} style={{height: "6px"}}/>*/}
        {/*      <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>*/}
        {/*        99,333 out of 150,000*/}
        {/*      </Typography>*/}
        {/*    </Box>*/}
        {/*  </AccountStyle>*/}
        {/*</Link>*/}

          <a href={translate('mainMenu.reviewCollectionLink')} target="_blank"
             style={{position: 'absolute', bottom: 35, textDecoration: 'none', minWidth: '85%'}}>
              <AccountStyle sx={{px: 1, paddingLeft: 0}}>
                  <Box style={{width: '100%'}}>
                      <MenuItem style={{width: '100%', height: '100%', pointerEvents: 'none'}}>
                          <ListItemIcon style={{minWidth: 0}} sx={{mr: 1, ml: 0}}>
                              <Icon icon={outlineChecklistRtl} width={24} height={24}/>
                          </ListItemIcon>
                          <ListItemText primaryTypographyProps={{variant: 'subtitle1'}}>
                            {translate('mainMenu.items.leaveReviewForVt')}
                          </ListItemText>
                      </MenuItem>
                  </Box>
              </AccountStyle>
          </a>
      </Stack>}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {width: DRAWER_WIDTH}
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

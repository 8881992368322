// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import {StrictMode} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
// contexts
import {SettingsProvider} from './contexts/SettingsContext';
import {CollapseDrawerProvider} from './contexts/CollapseDrawerContext';
//
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from 'contexts/JWTContext';

import { store, persistor } from './redux/store';

// i18n
import './locales/i18n';

// ----------------------------------------------------------------------

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <AuthProvider>
              <App/>
            </AuthProvider>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </SettingsProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

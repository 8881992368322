// material
import {Avatar, Box, BoxProps} from '@material-ui/core';
import {Icon} from "@mui/material";
import {makeStyles} from "@material-ui/styles";

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const useStyles = makeStyles({
    imageIcon: {
      height: '100%',
      margin: 'auto'
    },
    iconRoot: {
      textAlign: 'center',
      width: '100%'
    }
  });

  const classes = useStyles();

  return (
    <Box>
      <Icon classes={{root: classes.iconRoot}}>
        <img className={classes.imageIcon} src="/static/icons/ic_videotouch_logo.svg"/>
      </Icon>
    </Box>
  );
}

import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_DASHBOARD } from '../routes/paths';
import { useSearchParams } from "react-router-dom";

type GuestGuardProps = {
  children: ReactNode;
};

export const GuestRegisterGuard = ({ children }: GuestGuardProps) => {
  const { isAuthenticated } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  let navigateDest = searchParams.get("dest")
  if (!navigateDest) {
    navigateDest = PATH_DASHBOARD.greetings;
  } else {
    navigateDest = "/" + navigateDest;
  }

  if (isAuthenticated) {
    return <Navigate to={navigateDest} />;
  }

  return <>{children}</>;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  return <>{children}</>;
}


import {Comparer, createSlice} from '@reduxjs/toolkit';
import {RootState, store} from '../store';
import axios from '../../utils/axios';
import {createReviewAdapter} from "../utils";
import compareDesc from 'date-fns/compareDesc';
import {toDate} from '../../utils/formatTime';
import {ReviewData, ReviewState} from "../../@types/review";

const apiBase = "/api/v2/reviews"

const sortReviewsComparer: Comparer<ReviewData> = (a, b) => compareDesc(toDate(a.createdAt), toDate(b.createdAt));
const reviewAdapter = createReviewAdapter(sortReviewsComparer);

const initialState: ReviewState = {
  isLoading: false,
  error: false,
  reviewDataList: reviewAdapter.getInitialState(),
  reviewData: null,
  sortBy: null,
  deletingReview: null,
  editingReview: null
};

const slice = createSlice({
  name: 'review',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getReviewListSuccess(state, action) {
      state.isLoading = false;
      reviewAdapter.setAll(state.reviewDataList, action.payload);
    },

    getReviewSuccess(state, action) {
      state.isLoading = false;
      state.reviewData = action.payload;
    },

    onReviewInListOperationSuccess(state) {
      state.isLoading = false;
    },

    updateReview(state, action) {
      reviewAdapter.updateOne(state.reviewDataList, action.payload);
    },

    setReviewDelete(state, action) {
      state.deletingReview = action.payload;
    },

    setReviewEdit(state, action) {
      state.editingReview = action.payload;
    },

    deleteReviewFromState(state, action) {
      state.deletingReview = null;
      reviewAdapter.removeOne(state.reviewDataList, action.payload);
    },

    sortByReviews(state, action) {
      state.sortBy = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByReviews,
  setReviewDelete,
  setReviewEdit
} = slice.actions;

export function getReviewList() {
  return async () => {
    const {dispatch} = store;
    dispatch(slice.actions.startLoading());
    try {
      const response: { data: ReviewData[] } = await axios.get(apiBase);
      dispatch(slice.actions.getReviewListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function changeReviewLikeStatus(id: number, liked: boolean) {
  const request: any =
    {
      "id": id,
      "liked": liked
    };

  return patchReview(id, request)
}

export function restoreReview(id: number) {
  const request: any =
    {
      "id": id,
      "deleted": false
    };

  return patchReview(id, request)
}

export function patchReview(id: number, patch: any) {
  return async () => {
    const {dispatch} = store;
    dispatch(slice.actions.startLoading());

    try {
      await axios.patch(apiBase + '/' + id, patch);
      dispatch(slice.actions.updateReview({
        id,
        changes: patch
      }));
      dispatch(slice.actions.onReviewInListOperationSuccess());
      return true;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export const updateReview = (data: ReviewData) => {
  return async () => {
    const {dispatch} = store;
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(apiBase + '/' + data.id, data);
      dispatch(slice.actions.onReviewInListOperationSuccess());
      dispatch(slice.actions.updateReview({
        id: data.id,
        changes: {
          ...data
        }
      }));
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  }
};

export function deleteReview(id: number, permanently: boolean) {
  return async () => {
    const {dispatch} = store;
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(apiBase + '/' + id + "?permanently=" + permanently);
      dispatch(slice.actions.onReviewInListOperationSuccess());
      dispatch(slice.actions.deleteReviewFromState(id));
      return true;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function createReview(publicCampaignId: string, videoId: number) {
  return async () => {
    const {dispatch} = store;
    dispatch(slice.actions.startLoading());

    const request: any =
      {
        "publicCampaignId": publicCampaignId,
        "videoId": videoId
      };

    try {
      await axios.post(apiBase + '/create-existing', request);
      dispatch(getReviewList());
      return true;
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      return false;
    }
  };
}

export function selectAllNotDeletedReviews(state: RootState) {
  return selectAllReviews(state).filter(r => !r.deleted)
}

export function selectAllDeletedReviews(state: RootState) {
  return selectAllReviews(state).filter(r => r.deleted)
}

export const {
  selectAll: selectAllReviews,
  selectById: selectReviewById,
  selectIds: selectReviewIds
  // Pass in a selector that returns the posts slice of state
} = reviewAdapter.getSelectors((state: RootState) => state.review.reviewDataList)

import {Icon} from '@iconify/react';
import {useEffect, useRef, useState} from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import {Link as RouterLink} from 'react-router-dom';
// material
import {alpha} from '@material-ui/core/styles';
import {Avatar, Box, Button, Divider, MenuItem, Typography} from '@material-ui/core';
// components
import {MIconButton} from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import useAuth from "../../hooks/useAuth";
import useLocales from "../../hooks/useLocales";
import {dispatch, RootState, useSelector} from "../../redux/store";
import {getProfile, setLoadedUser} from "../../redux/slices/user";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {label: 'main.settings.home', icon: homeFill, linkTo: '/'},
  {label: 'main.settings.account', icon: settings2Fill, linkTo: '/profile'}
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const {logout} = useAuth();
  const {translate} = useLocales();

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  const { userProfile } = useSelector(
    (state: RootState) => state.user
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  if (!userProfile) {
    return null
  }

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src="/static/mock-images/avatars/avatar_default.jpg"/>
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{width: 220}}
      >
        <Box sx={{my: 1.5, px: 2.5}}>
          {/*name is optional*/}
          {userProfile.name && <Typography variant="subtitle1" noWrap>
            {userProfile.name}
          </Typography>}

          <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
            {userProfile.email}
          </Typography>
        </Box>

        <Divider sx={{my: 1}}/>

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{typography: 'body2', py: 1, px: 2.5}}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {translate(option.label)}
          </MenuItem>
        ))}

        <Box sx={{p: 2, pt: 1.5}}>
          <Button onClick={logout} fullWidth color="inherit" variant="outlined">
            {translate("main.settings.logout")}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

import {createSlice} from "@reduxjs/toolkit";
import {RootState, store} from "../store";
import axios from "../../utils/axios";
import {CREATE_WIDGET_MODS, CREATE_WIDGET_STAGES, WidgetCreateFormData, WidgetCreateSlice} from "../../@types/widget";
import {widgetCode} from "../../utils/widgetUtils";

const apiBase = '/api/v2';
const apiWidgetBase = `${apiBase}/widgets`

const initialState: WidgetCreateSlice = {
  createMode: CREATE_WIDGET_MODS[0],
  createWidgetManager: {
    isLoading: false,
    error: null,
    data: null,
  },
  uploadVideoManager: {
    isLoading: false,
    data: null,
    percent: 0
  },
  activeStage: {
    stageKey: CREATE_WIDGET_STAGES[0],
    isErrorOnTransition: false,
    isLoading: false
  },
  fields: {},
};

const slice = createSlice({
  name: 'widgetCreate',
  initialState,
  reducers: {
    setInitialState(state) {
      state.uploadVideoManager = {...initialState.uploadVideoManager};
      state.activeStage = {...initialState.activeStage};
    },
    setInitialWidgetFormState(state) {
      state.activeStage = {...initialState.activeStage};
      state.videoId = undefined;
    },
    setVideoId(state, {payload}) {
      state.videoId = payload;
    },

    setCreateWidgetStart(state) {
      state.createWidgetManager.isLoading = true;
    },
    setCreateWidgetFinish(state, { payload }) {
      state.createWidgetManager.isLoading = false;
      state.createWidgetManager.data = payload.data;
      if (payload.error !== null) {
        state.createWidgetManager.error = payload.error;
      }
    },
    setUploadVideoPercent(state, {payload}) {
      state.uploadVideoManager.percent = payload;
    },
    setUploadVideoData(state, { payload }) {
      state.uploadVideoManager.data = payload;
    },
    setUploadVideoLoadingState(state, { payload }) {
      state.uploadVideoManager.isLoading = payload;
    },
    setActiveStageTransitionStart(state, { payload }) {
      state.activeStage.stageKey = payload.stageKey;

      if (!payload.isImmediateTransition) { // needed for non-async transition
        state.activeStage.isLoading = true;
      }
    },
    setActiveStageTransitionStop(state, { payload }) {
      state.activeStage.isLoading = false;
      if (payload?.isErrorOnTransition) {
        state.activeStage.isErrorOnTransition = Boolean(payload.isErrorOnTransition);
      }
    },
    setFields(state, { payload }) {
      state.fields = {
        ...state.fields,
        ...payload };
    },
    setCreateMode(state, { payload }) {
      state.createMode = payload;
    },
  }
});

export const {
  setInitialState,
  setVideoId,
  setUploadVideoPercent,
  setUploadVideoLoadingState,
  setActiveStageTransitionStart,
  setActiveStageTransitionStop,
  setFields,
  setInitialWidgetFormState,
  setCreateMode,
} = slice.actions;

export const createWidget = (widgetData: WidgetCreateFormData) => async () => {
  const { dispatch } = store;

  dispatch(slice.actions.setCreateWidgetStart());

  try {
    const { data } = await axios.post(`${apiWidgetBase}/create`, widgetData);
    const result = {data: data, error: null}
    dispatch(slice.actions.setActiveStageTransitionStop({}));
    console.log("Response: "+ JSON.stringify(result));
    dispatch(slice.actions.setCreateWidgetFinish(
      result
    ));

    window.mixpanel?.track("created widget");
  } catch (error) {
      throw error;
  }
};

export const getWidgetCreate = (state: RootState) => state.widgetCreate;

export const getActiveStageObj = (state: RootState) => getWidgetCreate(state).activeStage;
export const getActiveStageKey = (state: RootState) => getActiveStageObj(state).stageKey;
export const getActiveStageLoadingState = (state: RootState) => getActiveStageObj(state).isLoading;

export const getCreateWidgetManager = (state: RootState) => getWidgetCreate(state).createWidgetManager;
export const getCreateWidgetManagerData = (state: RootState) => getCreateWidgetManager(state).data;
export const getWidgetCode = (state: RootState) => widgetCode(getCreateWidgetManagerData(state)?.publicId, isEmbedded(state));

export const getUploadVideoManager = (state: RootState) => getWidgetCreate(state).uploadVideoManager;
export const getUploadVideoManagerProgress = (state: RootState) => getUploadVideoManager(state).percent;
export const getUploadVideoManagerLoadingState = (state: RootState) => getUploadVideoManager(state).isLoading;
export const getCreateMode = (state: RootState) => getWidgetCreate(state).createMode;
export const isEmbedded = (state: RootState) => getCreateWidgetManagerData(state)?.embedded;

export const getVideoId = (state: RootState) => getWidgetCreate(state).videoId;

export default slice.reducer;


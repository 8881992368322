import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';
import videoReducer from './slices/videos';
import widgetReducer from './slices/widgets';
import userReducer from './slices/user';
import campaignReducer from './slices/campaigns';
import videoPlayerManager from './slices/videoPlayerManager';
import campaignCreate from './slices/campaignCreate';
import applicationState from "./slices/applicationState";
import statisticsReducer from "./slices/statistics";
import reviewReducer from "./slices/reviews";
import referralReducer from "./slices/referral";
import testimonialsCollection from "./slices/testimonialsCollection";
import widgetCreate from "./slices/widgetCreate";

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  user: userReducer,
  widget: widgetReducer,
  paymentDetails: userReducer,
  video: videoReducer,
  campaign: campaignReducer,
  statistics: statisticsReducer,
  referral: referralReducer,
  review: reviewReducer,
  testimonialsCollection: testimonialsCollection,
  videoPlayerManager,
  campaignCreate,
  widgetCreate,
  applicationState,
});

export { rootPersistConfig, rootReducer };

import React, { ReactNode } from 'react';

import Typography from '@mui/material/Typography';
import Card, { CardProps } from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';

type StepCardProps = {
    children: ReactNode;
    title: string | JSX.Element;
    upperSubtitle?: string;
    lowerSubtitle?: string;
} & CardProps;

type SubtitleProps = {
    children: ReactNode;
}

const UpperSubtitle = ({ children }: SubtitleProps) => (<Typography align="center" variant="body1" sx={{pb: 2, pt: 0, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
    {children}
</Typography>);
const LowerSubtitle = ({ children }: SubtitleProps) => (<Typography align="center" variant="body1" sx={{pt: 2, fontSize: '0.9rem', color: (theme) => theme.palette.grey[500]}}>
    {children}
</Typography>);

const StepCard = ({ children, upperSubtitle, lowerSubtitle, title, sx }: StepCardProps) => {

    return (<Card sx={{
        background: '#f4f6f8',
        boxShadow: 'none',
        px: 3,
        pt: {
            xs: 3,
            sm: 3
        },
        pb: {
            xs: 3,
            sm: 8
        },
        mb: 3,
        ...sx
    }}>
        <CardHeader
            title={
                <>
                    {upperSubtitle && <UpperSubtitle>{upperSubtitle}</UpperSubtitle>}
                    <Typography variant="h2" align="center" sx={{ color: 'primary.main', px: '5%' }}>
                        {title}
                    </Typography>
                    {lowerSubtitle && <LowerSubtitle>{lowerSubtitle}</LowerSubtitle>}
                </>
            }
            sx={{mb: (children ? 3 : 0)}}
        />
        <Grid container spacing={3} style={{justifyContent: 'center'}}>
            {children && (<Grid item xs={12} md={10} sx={{ justifyContent: 'center', width: '100%', margin: 'auto' }}>
                {children}
            </Grid>)}
        </Grid>
    </Card>);
};

export default StepCard;
